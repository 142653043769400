<div class="content">
    <section class="broadcast-section">
        <div class="container-fluid">
            <div class="row">
                <!-- Main Content -->
                <div class="col-md-9">
                    <div class="headline-with-buttons">
                        <div class="row">
                            <div class="col-md-6">
                                <h3 class="mb-0 mt-2">Broadcast Message</h3>
                            </div>
                        </div>
                    </div>

                    <!-- Broadcast Form -->
                    <form [formGroup]="broadcastForm" (ngSubmit)="sendBroadcast()">
                        <div class="broadcast-box">
                            <div class="box-content">
                                <!-- Message Input -->
                                <div class="broadcast-message-input mt-4">
                                    <textarea 
                                        id="broadcastMsg" 
                                        rows="5" 
                                        class="form-control" 
                                        formControlName="message" 
                                        placeholder="Enter Broadcast message"
                                        [(ngModel)]="broadcastMsg">
                                    </textarea>
                                </div>

                                <!-- Submit Button -->
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-success" [disabled]="!broadcastForm.valid">
                                        <i class="fas fa-paper-plane mr-2"></i> Send Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Sidebar (Optional) -->
                <div class="col-md-3 col-sm-6 pl-0">
                    <!-- Add past broadcasts, analytics, or extra info here -->
                </div>
            </div>
        </div>
    </section>
</div>

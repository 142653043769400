import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import {finalize,catchError,tap} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentsService } from '../../components.service';
import { AllItemsDialogComponent } from '../digital-circular/all-items-dialog';
import { MatDialog } from '@angular/material/dialog';
import * as Papa from 'papaparse';


@Component({
	selector: 'app-recipe-view',
	templateUrl: './recipe-view.component.html',
	styleUrls: ['./recipe-view.component.scss']
})
export class RecipeViewComponent implements OnInit {

search_icon_form:FormGroup;
recipe_add_form: FormGroup;
product_add_form: FormGroup;


products_list:any;
ref:any;
task:any;
imagesrc:any = "../../assets/images/Rectangle 316.png";
imageurl:any;
current_store_uid:any;
user:any;
id:any;
recipe_data:any;
recipe_products:any;
importcsvdata:any = [];
master_list:any;
saved_items_list:any;
show_add_product:boolean = false;
saved_items_list_temp:any;
duplicates_temp:any;
recipe_id:any;
closeResult:any;
checkbox_array:any = [];
show_icon_results:any;
icons:any;
image_uploading:boolean = false;
imageurl2:any = '';
current_unsplash_type:any;
categories_list:any;
ghost_mode:boolean = false;
get_current_store_id:any;
filterData:any;


@ViewChild('importSavedModal') importSavedModal;
@ViewChild('iconSearchModal') iconSearchModal;


constructor( @Inject(PLATFORM_ID) 
	private platformId  : object,
	private route       : ActivatedRoute,
	private router      : Router,
	private formBuilder : FormBuilder,
	public authService  : AuthService,
	public afs          : AngularFirestore,
	public afAuth       : AngularFireAuth,
	private afStorage   : AngularFireStorage,
	private modalService: NgbModal,
	private storage     : AngularFireStorage,
	private _snackBar   : MatSnackBar,
	private http 		: HttpClient, 
	public dialog: MatDialog,
	private componentService: ComponentsService
	) { 

		this.recipe_add_form = new FormGroup({
			recipe_cover_image      	: new FormControl(""),
			recipe_name         		: new FormControl(""),
			ingredients         		: new FormControl(""),  
			instructions        		: new FormControl(""), 
			products_array        		: new FormControl([]),
			storeInMasterRecipe			: new FormControl(),
		});

		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});

		this.product_add_form = new FormGroup({
			uid           : new FormControl(""),
			product_image       : new FormControl(""),  
			upc_code        : new FormControl(""), 
			product_description   : new FormControl(""),  
			pack          : new FormControl(""),
			product_url       : new FormControl(""),
			category        : new FormControl(""),
			price_label_format    : new FormControl(""),
			hide_price_label    : new FormControl(false),
			hide_addtolist_label  : new FormControl(false),
			hide_productname_label  : new FormControl(false),
		});
	
	}

	ngOnInit(): void {
		this.componentService.recipeEvent.subscribe(res => {
			let data = this.componentService.getRecipeArray()
			this.recipe_products.push(...data)
		})
		let chkghost = localStorage.getItem('ghost_mode');
  		if(chkghost)
  		{
  			this.ghost_mode = true;
  		} else {
  			this.ghost_mode = false;
  		}
  		this.get_current_store_id = localStorage.getItem('current_store_id');
  		if(this.get_current_store_id)
  		{
  			this.user = JSON.parse(this.get_current_store_id);
  		} else{
  			this.user = JSON.parse(localStorage.getItem('userrole'));
  		}
  		this.current_store_uid = this.user.store_uid;

  		


		this.id = this.route.snapshot.params['id'];
		this.recipe_id = this.id;

		//this.user = JSON.parse(localStorage.getItem('userrole'));
		//this.current_store_uid = this.user.store_uid;
		this.afs.collection('/saved-items',ref=>ref.where('store_uid','==', this.current_store_uid)).snapshotChanges().subscribe(data => {
			this.products_list = data.map(e => {
				return{
					uid           			: e.payload.doc.id,
					upc_code        		: e.payload.doc.data()['upc_code'],
					product_description   	: e.payload.doc.data()['product_description'],
					pack          			: e.payload.doc.data()['pack'],
					price_label_format    	: e.payload.doc.data()['price_label_format'],
					price_label_value1    	: e.payload.doc.data()['price_label_value1'],
					price_label_value2    	: e.payload.doc.data()['price_label_value2'],
					price_label_value3    	: e.payload.doc.data()['price_label_value3'],
					price_label_highlight   : e.payload.doc.data()['price_label_highlight'],
					product_url       		: e.payload.doc.data()['product_url'],
					hide_price_label    	: e.payload.doc.data()['hide_price_label'],
					hide_addtolist_label  	: e.payload.doc.data()['hide_addtolist_label'],
					hide_productname_label  : e.payload.doc.data()['hide_productname_label'],
					cost          			: e.payload.doc.data()['cost'],
					srp           			: e.payload.doc.data()['srp'],
					category        		: e.payload.doc.data()['category'],
					product_image       	: e.payload.doc.data()['product_image']?e.payload.doc.data()['product_image']:"https://via.placeholder.com/30/cecece/cecece",
					price_label_svg     	: e.payload.doc.data()['price_label_svg'],
				};
			});
		});

		this.afs.collection('/recipes',ref=>ref.where('uid','==', this.id)).snapshotChanges().subscribe(data => {
			this.recipe_data = data.map(e => {
				return{
					uid           			: e.payload.doc.id,
					recipe_cover_image      : e.payload.doc.data()['recipe_cover_image'],
					recipe_name   			: e.payload.doc.data()['recipe_name'],
					ingredients          	: e.payload.doc.data()['ingredients'],
					instructions    		: e.payload.doc.data()['instructions'],
					products_array			: e.payload.doc.data()['products_array'],
					storeInMasterRecipe		: e.payload.doc.data()['storeInMasterRecipe'],
				};
			});
			this.imagesrc = this.recipe_data[0]?.recipe_cover_image;
			this.recipe_products = this.recipe_data[0]?.products_array;
			this.recipe_add_form.patchValue({
				recipe_cover_image 		: this.recipe_data[0]?.recipe_cover_image,
				recipe_name  			: this.recipe_data[0]?.recipe_name,
				ingredients  			: this.recipe_data[0]?.ingredients,
				instructions 			:  this.recipe_data[0]?.instructions,
				storeInMasterRecipe		: this.recipe_data[0]?.storeInMasterRecipe ? true : false,
			});
		});

		this.afs.collection('/store-items',ref=>ref.where('store_uid','==', this.current_store_uid).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list_temp = data.map(e => {
				i++;
				return{
					
					upc_code        		: e.payload.doc.data()['upc_code'],
					product_description   	: e.payload.doc.data()['product_description'],
					pack          			: e.payload.doc.data()['pack'],
					price_label_format    	: e.payload.doc.data()['price_label_format'],
					price_label_value1    	: e.payload.doc.data()['price_label_value1'],
					price_label_value2    	: e.payload.doc.data()['price_label_value2'],
					price_label_value3    	: e.payload.doc.data()['price_label_value3'],
					price_label_highlight 	: e.payload.doc.data()['price_label_highlight'],
					product_url           	: e.payload.doc.data()['product_url'],
					hide_price_label      	: e.payload.doc.data()['hide_price_label'],
					hide_addtolist_label  	: e.payload.doc.data()['hide_addtolist_label'],
					hide_productname_label	: e.payload.doc.data()['hide_productname_label'],
					cost                  	: e.payload.doc.data()['cost'],
					srp                   	: e.payload.doc.data()['srp'],
					category              	: e.payload.doc.data()['category']?e.payload.doc.data()['category']:"--",
					uid                   	: e.payload.doc.id,
					product_image         	: e.payload.doc.data()['product_image']?e.payload.doc.data()['product_image']:"https://via.placeholder.com/30/cecece/cecece",
					price_label_svg       	: e.payload.doc.data()['price_label_svg'],
					serial                	: i-1,
					is_saved              	: e.payload.doc.data()['is_saved']?e.payload.doc.data()['is_saved']:false
					//created_at          	: e.payload.doc.data()['created_at'],
				};
			}); 
		});


		this.afs.collection('/store-items',ref=>ref.where('store_uid','==', this.current_store_uid).where('is_saved','==',true).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list = data.map(e => {
				i++;
				return{
					upc_code 							: e.payload.doc.data()['upc_code'],
					product_description 				: e.payload.doc.data()['product_description'],
					pack 								: e.payload.doc.data()['pack'],
					price_label_format 					: e.payload.doc.data()['price_label_format'],
					price_label_value1 					: e.payload.doc.data()['price_label_value1'],
					price_label_value2					: e.payload.doc.data()['price_label_value2'],
					price_label_value3					: e.payload.doc.data()['price_label_value3'],
					price_label_highlight 				: e.payload.doc.data()['price_label_highlight'],
					product_url 						: e.payload.doc.data()['product_url'],
					hide_price_label 					: e.payload.doc.data()['hide_price_label'],
					hide_addtolist_label				: e.payload.doc.data()['hide_addtolist_label'],
					hide_productname_label 				: e.payload.doc.data()['hide_productname_label'],
					cost 								: e.payload.doc.data()['cost'],
					srp 								: e.payload.doc.data()['srp'],
					category 							: e.payload.doc.data()['category'],
					uid 								: e.payload.doc.id,
					product_image 						: e.payload.doc.data()['product_image']?e.payload.doc.data()['product_image']:"https://via.placeholder.com/30/cecece/cecece",
					price_label_svg 					: e.payload.doc.data()['price_label_svg'],
					serial 								: i-1,
					is_saved 							: e.payload.doc.data()['is_saved']
					//created_at  						: e.payload.doc.data()['created_at'],
				};
			}); 
			this.filterData = this.saved_items_list
	  	});


	  	this.afs.collection('/lookup-categories',ref => ref.orderBy('sort','asc')).snapshotChanges().subscribe(data => {
			this.categories_list = data.map(e => {
				return{
					uid           : e.payload.doc.id,
					value           : e.payload.doc.data()['value'],
					sort          : e.payload.doc.data()['sort'],
				};
			}); 
		});


	}




	backToRecipeDashboard(){
		this.router.navigate(['/store/recipes']);
	}

	upload(event,ind) {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			const reader = new FileReader();
			reader.onload = e =>  this.imagesrc = reader.result;
			// this.imageurl1 = reader.result;
			// this.imageurl2 = reader.result;
		
			reader.readAsDataURL(file);
		}

		// create a random id
		const randomId = "digital-circular-"+ new Date().getTime() +  Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/'+randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task =  this.ref.put(event.target.files[0]);

		//this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
			finalize(() => {
			this.ref.getDownloadURL().subscribe(url => {

				if(ind == '1')  
				{ 
				this.imagesrc = url;
				this.recipe_add_form.patchValue({
					recipe_cover_image  :  url,
				});
				}
				if(ind == '2')  
				{ 
					this.imageurl2 = url;
					this.product_add_form.patchValue({
						product_image  :  url,
					});
				}

				

				// let store_data = {
				//  store_logo  : url,
				// };
				// let update_to_db = this.afs.doc('/stores/'+this.current_store_uid).set(store_data,{merge:true}).then(docRef => {
				// });



			});
			})
		).subscribe(); 


		// this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";
	}

	trigger_from_save_btn()
	{
		let element: HTMLElement = document.getElementsByClassName('submit_form')[0] as HTMLElement;
		element.click();
	}

	store_recipe(value)
	{
		let recipe_data = {
			recipe_name       		: value.recipe_name,
			recipe_cover_image    	: value.recipe_cover_image, 
			ingredients       		: value.ingredients,
			instructions      		: value.instructions,
			products_array      	: this.recipe_products,
			storeInMasterRecipe		: value.storeInMasterRecipe
		};


		//save newly created recipe to database
		let save_to_db = this.afs.doc('/recipes/'+ this.id).set(recipe_data,{merge:true}).then(docRef => {
			this._snackBar.open("Recipe updated successfully!",'',{
			duration: 1500,
			});
			this.router.navigate(['/store/recipes']);

		});
	}

	remove_recipe_product(index)
	{
		this.recipe_products.splice(index,1);
	}

	import_from_saved()
	{
		// this.open(this.importSavedModal,'modal-basic-title6');
		const dialogRef = this.dialog.open(AllItemsDialogComponent, {
			width: '800px',
			height: '700px',
			data: {from:'recipebox'}
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}

	doFilter = (value: string) => {
		if(!value){
			this.filterData = this.saved_items_list
		}else{
			this.filterData=[]
			this.filterData = this.saved_items_list.filter(el => el.upc_code?.includes(value) || el.product_description?.includes(value) );
		}

	}

	add_manual()
	{
		this.show_add_product = true;
	}

	trigger_import_csv()
	{
		let element: HTMLElement = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
		element.click();
	}

	import_csv(files: FileList){
		if(files && files.length > 0) {
			let file : File = files.item(0);
			let reader: FileReader = new FileReader();
			let fileid = new Date().getTime();      //setting temporary name so that we can know what was the source of the upload
			reader.readAsText(file, 'UTF-8');
			reader.onload = (e) => {
				let filestring: string = reader.result as string;

				Papa.parse(filestring, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
					  // Process the parsed CSV data here
					  if (result.data && result.data.length > 0) {
						this.importcsvdata = result.data.map(row => {
							const tag = row.Description.split(" ")
							let product_name_slug = row.Description.replace(`'`, '');
							
							let product_upload_data = {
								upc_code: row.UPC,
								product_description: row.Description,
								pack: row.Pack,
								srp: row.SRP,
								category: row.Category,
								upload_file_id: fileid,
								product_image: "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726",
								product_name_slug: product_name_slug,
								price_label_format: row.PriceLabelFormat ? row.PriceLabelFormat : "",
								price_label_svg: '',
								price_label_value1: '',
								price_label_value2: '',
								price_label_value3: '',
								price_label_highlight: row.PriceLabelHighlight ? row.PriceLabelHighlight : "",
								product_url: row.ProductUrl ? row.ProductUrl : "",
								hide_price_label: row.HidePriceLable ? row.HidePriceLable : "",
								hide_addtolist_label: row.HideAddtolistLabel ? row.HideAddtolistLabel : "",
								hide_productname_label: row.HideProductnameLabel ? row.HideProductnameLabel : "",
								cost: row.Cost ? row.Cost : "",
								store_uid: this.current_store_uid,
								system_notes: "Uploaded using csv from saved-items",
								new_window: row.NewWindow ? row.NewWindow : "",
								autoplay: row.AutoPlay ? row.AutoPlay : "",
								secondery_product_image: [],
								tag: tag ? tag : []
						  	};
							 // Your specific logic for handling the "cols[3]" data
							 if (row.SRP) {
								const srpValue = parseFloat(row.SRP);
								if (!isNaN(srpValue)) {
									product_upload_data.price_label_value1 = srpValue.toFixed(2);
									const srpString = product_upload_data.price_label_value1;
									if (row.SRP.includes('/off')) {
										product_upload_data.price_label_format = "format7";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (row.SRP.includes('Buy')) {
										product_upload_data.price_label_format = "format6";
									} else if (row.SRP.includes('each') || row.SRP.includes('/lb') || row.SRP.includes('pound')) {
										product_upload_data.price_label_format = "format4";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (row.SRP.includes('/') || row.SRP.includes('for')) {
										product_upload_data.price_label_format = "format5";
									} else if (srpString.includes('.')) {
										product_upload_data.price_label_format = "format2";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else {
										product_upload_data.price_label_format = "format1";
									}
								}
							}
							return product_upload_data;
						});
					  }
					},
				});
				let result = this.saved_items_list_temp.filter(o1 => this.importcsvdata.some(o2 => o1.upc_code === o2.upc_code));
				this.duplicates_temp = result;
				if(this.importcsvdata.length == 0)
				{
					this._snackBar.open("No products to import.",'',{
						duration: 1500,
					});
				} 
				if(result.length > 0)
				{
					// this._snackBar.open("Some products already exist in the system.",'',{
					//  duration: 1500,
					// });
					//this.open(this.uploadExistModal,'modal-basic-title1'); 

					this.importcsvdata.forEach((value,key) => {
					let value2 = [];
					value2.push(value);
					let result2 = value2.filter(o1 => this.saved_items_list_temp.some(o2 => o1.upc_code === o2.upc_code));
					if(result2.length == 0)
					{
						//only import unique values thats why storing the unique values here
						let save_to_db = this.afs.collection('/store-items').add(value).then(docRef => {
							const itemRefx2 = this.afs.doc('/store-items/' + docRef.id);
							itemRefx2.update({ uid: docRef.id}); 
							let recipe_product_data = {
								store_uid 				: this.current_store_uid,
								recipe_uid 				: this.recipe_id,
								product_uid 			: docRef.id,
								upc_code 				: value.upc_code,
								product_image 			: value.product_image,
								product_description 	: value.product_description
							};
							this.recipe_products.push(recipe_product_data);
						});
					} else {
					let result3 = this.saved_items_list_temp.filter(o1 => value2.some(o2 => o1.upc_code === o2.upc_code));
						let recipe_product_data = {
								store_uid 				: this.current_store_uid,
								recipe_uid 				: this.recipe_id,
								product_uid 			: result3[0].uid,
								upc_code 				: result3[0].upc_code,
								product_image 			: result3[0].product_image,
								product_description 	: result3[0].product_description
							};
						this.recipe_products.push(recipe_product_data);	
					}
				});
				}
				if(result.length == 0)
				{
					this.importcsvdata.forEach((value,key)=>{
						value.created_at = new Date();
						let save_to_db = this.afs.collection('/store-items').add(value).then(docRef => {
							const itemRefx2 = this.afs.doc('/store-items/' + docRef.id);
							itemRefx2.update({ uid: docRef.id}); 
							let recipe_product_data = {
								store_uid 				: this.current_store_uid,
								recipe_uid 				: this.recipe_id,
								product_uid 			: docRef.id,
								upc_code 				: value.upc_code,
								product_image 			: value.product_image,
								product_description 	: value.product_description
							};
							this.recipe_products.push(recipe_product_data);

						});

						let check_master_data_exists = this.master_list.find(s => s.upc_code == value.upc_code);
						if(check_master_data_exists)
						{ 
						// this upc code  is already in master so ignore this product. 
						
						} else{
							//this product was not found in master db so add it.
							let save_to_master = this.afs.collection('/master-items').add(value).then(docRef => {
								const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
								itemRefx2.update({ uid: docRef.id}); 
							});
						}
						

					});
					this._snackBar.open("Items imported successfully!",'',{
						duration: 1500,
					});
					
				}
			}
		}
	}

	open(content,title) {
		this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return  `with: ${reason}`;
		}
	}

	checkbox_select_event(event,data,i)
	{
		if(event.target.checked == true)
		{
			let recipe_product_data = {
				store_uid 				: this.current_store_uid,
				recipe_uid 				: this.recipe_id,
				product_uid 			: data.uid,
				upc_code 				: data.upc_code,
				product_image 			: data.product_image,
				product_description 	: data.product_description
			};
			this.recipe_products.push(recipe_product_data);
			this.checkbox_array[i] = true;
		} else {
			//this.checkbox_delete_array[i] = false;
			this.checkbox_array[i] = true;
			let index = this.recipe_products.findIndex(el => el.upc_code == data.upc_code);
			this.recipe_products.splice(0,index);
		}
	}


	submit_search_icon(value)
	{
		this.http
			.get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&order_by=relevant&query='+value.query+'&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
			.subscribe(res => {  this.show_icon_results = true; });
	}

	product_store(data)
	{
		if(data.upc_code == "" || data.upc_code == null)
		{
			this._snackBar.open("Error:UPC Code is Required",'',{
				duration: 1500,
			});
			return false;
		}
		if(data.product_description == "" || data.product_description == null)
		{
			this._snackBar.open("Error:Product description is Required",'',{
				duration: 1500,
			});
			return false;
		}
		let temp_store = [];
		temp_store.push(data);
		let result = this.saved_items_list_temp.filter(o1 => temp_store.some(o2 => o1.upc_code === o2.upc_code));
		if(result.length > 0)
		{
			let product_id = result[0].uid;
			let product_upc = result[0].upc_code;
			let recipe_product_data = {
				store_uid:  this.current_store_uid,
				recipe_uid: this.recipe_id,
				product_uid: product_id,
				upc_code: product_upc,
				product_image: result[0].product_image,
				product_description: result[0].product_description
			};

			this.recipe_products.push(recipe_product_data);
			return false;
		}

		data.price_label_value1 = "";
		data.price_label_value2 = "";
		data.price_label_value3 = "";
		data.price_label_highlight =  "";
		data.cost = "";
		data.srp  = "";

		data.created_at = new Date();
		data.system_notes = "Created manually using form";
		data.store_uid = this.current_store_uid;
		const itemRefx = this.afs.collection('/store-items').add(data).then(docRef => {
					const itemRefx2= this.afs.doc('/store-items/' + docRef.id);
					itemRefx2.update({ uid: docRef.id}); 
					this.product_add_form.reset();

					let recipe_product_data = {
						store_uid:  this.current_store_uid,
						recipe_uid: this.recipe_id,
						product_uid: docRef.id,
						upc_code: data.upc_code,
						product_image: data.product_image,
						product_description: data.product_description
					};

			this.recipe_products.push(recipe_product_data);


			});
		this._snackBar.open("Product added successfully!",'',{
			duration: 1500,
		});
	}

	trigger_upload_image()
	{
		let element: HTMLElement = document.getElementsByClassName('update_image_upload')[0] as HTMLElement;
		element.click();
	}
	trigger_product_store_click()
	{
		let element: HTMLElement = document.getElementsByClassName('submit_product_add')[0] as HTMLElement;
		element.click();
	}

	trigger_upload_image_add()
	{
		let element: HTMLElement = document.getElementsByClassName('update_image_upload2')[0] as HTMLElement;
		element.click(); 
	}

	openIconModal(type)
	{
		this.current_unsplash_type = type;
		this.open(this.iconSearchModal,'modal-basic-title5');
		//https://api.iconify.design/search?query=dashboard&limit=120
		//https://api.iconify.design/akar-icons/home.svg?color=%2371B01D&height=56
	}

	set_current_image(value)
	{
		//setting image from unsplash;
		if(this.current_unsplash_type == 'add')
		{
			//setting unsplash image wheen adding a product
			this.imageurl2 = value; 
			this.product_add_form.patchValue({
				product_image   :  value,
			});
		} 
		let element: HTMLElement = document.getElementsByClassName('close_unsplash_modal')[0] as HTMLElement;
		element.click();
		
	}



}
import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import {finalize,catchError,tap} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Papa from 'papaparse';



@Component({
  selector: 'app-product-image-detail',
  templateUrl: './product-image-detail.component.html',
  styleUrls: ['./product-image-detail.component.scss']
})
export class ProductImageDetailComponent implements OnInit {

 
datasource = [];
tableColumns  :  string[] = ['checkbox','id', 'upc', 'image','product_desc','category','price','action'];
title = 'appBootstrap';
saved_items_list:any;
user:any;
current_store_uid:any;
importcsvdata:any = [];
saved_items_list_temp:any;
duplicates_temp:any;
closeResult:any;
current_selected_product:any;
imageurl1:any = '';
imageurl2:any = '';
ref:any;
task:any;
imageSrc:any;
imageurl:any;
show_add_product:boolean = false;
checkbox_delete_array:any = [];
checkbox_select_all:any;
categories_list:any;

product_edit_form: FormGroup;
product_add_form: FormGroup;
image_uploading:boolean = false;
show_icon_results:any;
icons:any;
current_unsplash_type:any;

search_icon_form:FormGroup;
@ViewChild('iconSearchModal') iconSearchModal;
@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('uploadExistModal') uploadExistModal;
@ViewChild('deleteModal') deleteModal;


  constructor( @Inject(PLATFORM_ID) 
  private platformId: object,
  private route: ActivatedRoute,
  private router:Router,
  private formBuilder: FormBuilder,
  public authService: AuthService,
  public afs: AngularFirestore,
  public afAuth: AngularFireAuth,
  private afStorage : AngularFireStorage,
  private http: HttpClient, 
  private modalService: NgbModal,
  private storage: AngularFireStorage,
  private _snackBar: MatSnackBar
  ) {

    this.product_edit_form = new FormGroup({
      uid           : new FormControl(""),
      product_image       : new FormControl(""),  
      upc_code        : new FormControl(""), 
      product_description   : new FormControl(""),  
      pack          : new FormControl(""),
      product_url       : new FormControl(""),
      category        : new FormControl(""),
      price_label_format    : new FormControl(""),
      hide_price_label    : new FormControl(false),
      hide_addtolist_label  : new FormControl(false),
      hide_productname_label  : new FormControl(false),
    });

    this.product_add_form = new FormGroup({
      uid           : new FormControl(""),
      product_image       : new FormControl(""),  
      upc_code        : new FormControl(""), 
      product_description   : new FormControl(""),  
      pack          : new FormControl(""),
      product_url       : new FormControl(""),
      category        : new FormControl(""),
      price_label_format    : new FormControl(""),
      hide_price_label    : new FormControl(false),
      hide_addtolist_label  : new FormControl(false),
      hide_productname_label  : new FormControl(false),
    });

    this.search_icon_form = new FormGroup({
      query: new FormControl(null, Validators.compose([Validators.required])),
    });

  }


  downloadFile(data: any) {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, "saved-items-"+new Date().getTime()+".csv");
  }

  openIconModal(type)
  {
    this.current_unsplash_type = type;
    this.open(this.iconSearchModal,'modal-basic-title5');
    //https://api.iconify.design/search?query=dashboard&limit=120
    //https://api.iconify.design/akar-icons/home.svg?color=%2371B01D&height=56
  }


  submit_search_icon(value){
    this.http
          .get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&query='+value.query+'&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
          .subscribe(res => {  this.icons  = res['results']; this.show_icon_results = true; });
  }



  export_csv(){
    this.downloadFile(this.saved_items_list_temp);
    this._snackBar.open("File downloaded successfully!",'',{
      duration: 1500,
    });
  }

  set_current_image(value){
    if(this.current_unsplash_type == 'edit'){
      this.imageurl1 = value; 
      this.product_edit_form.patchValue({
        product_image   : value,
      });
    } 
    if(this.current_unsplash_type == 'add'){
      this.imageurl2 = value; 
      this.product_add_form.patchValue({
        product_image   :  value,
      });
    } 
    let element: HTMLElement = document.getElementsByClassName('close_unsplash_modal')[0] as HTMLElement;
    element.click();
  }

    ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userrole'));
    this.current_store_uid = this.user.store_uid;
    this.afs.collection('/master-items',ref => ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
      let i = 0;
      this.saved_items_list_temp = data.map(e => {
        i++;
        return{
          upc_code                : e.payload.doc.data()['upc_code'],
          product_description     : e.payload.doc.data()['product_description'],
          pack                    : e.payload.doc.data()['pack'],
          price_label_format      : e.payload.doc.data()['price_label_format'],
          price_label_value1      : e.payload.doc.data()['price_label_value1'],
          price_label_value2      : e.payload.doc.data()['price_label_value2'],
          price_label_value3      : e.payload.doc.data()['price_label_value3'],
          price_label_highlight   : e.payload.doc.data()['price_label_highlight'],
          product_url             : e.payload.doc.data()['product_url'],
          hide_price_label        : e.payload.doc.data()['hide_price_label'],
          hide_addtolist_label    : e.payload.doc.data()['hide_addtolist_label'],
          hide_productname_label  : e.payload.doc.data()['hide_productname_label'],
          cost                    : e.payload.doc.data()['cost'],
          srp                     : e.payload.doc.data()['srp'],
          category                : e.payload.doc.data()['category'],
          uid                     : e.payload.doc.id,
          product_image           : e.payload.doc.data()['product_image']?e.payload.doc.data()['product_image']:"https://via.placeholder.com/30/cecece/cecece",
          price_label_svg         : e.payload.doc.data()['price_label_svg'],
          serial                  : i-1,
          is_saved                : e.payload.doc.data()['is_saved']?e.payload.doc.data()['is_saved']:false
          //created_at            : e.payload.doc.data()['created_at'],
        };
      }); 

      this.saved_items_list = this.saved_items_list_temp;
      this.saved_items_list = new MatTableDataSource(this.saved_items_list);
      this.saved_items_list.sort = this.leadsSort;
      this.saved_items_list.paginator = this.leadsPaginator;
      });


      this.afs.collection('/lookup-categories',ref => ref.orderBy('sort','asc')).snapshotChanges().subscribe(data => {
      this.categories_list = data.map(e => {
        return{
          uid           : e.payload.doc.id,
          value           : e.payload.doc.data()['value'],
          sort          : e.payload.doc.data()['sort'],
        };
      }); 
    });
    }

  goToAddNewStore(){
    this.router.navigate(['./add-new-store']);
  }

  doFilter = (value: string) => {
        this.saved_items_list.filter = value.trim().toLocaleLowerCase();
  }

  view_product(data)
  {
    this.show_add_product = false;
    this.current_selected_product = data;
    this.imageurl1 = data.product_image + '&size=418&thumbnail=true&palette=RGB';
    this.product_edit_form.patchValue({
      uid         : data.uid,
      product_image   : data.product_image,
      upc_code      : data.upc_code,
      product_description : data.product_description,
      pack        : data.pack,
      price_label_format  : data.price_label_format,
      category      : data.category,
      product_url     : data.product_url,
      hide_price_label  : data.hide_price_label,
      hide_addtolist_label: data.hide_addtolist_label,
      hide_productname_label : data.hide_productname_label,

    });
  }
  
  import_csv(files: FileList){
    if(files && files.length > 0) {
      let file : File = files.item(0); 
      let reader: FileReader = new FileReader();
      let fileid = new Date().getTime();      //setting temporary name so that we can know what was the source of the upload
      reader.readAsText(file, 'UTF-8');
			reader.onload = (e) => {
				let filestring: string = reader.result as string;

				Papa.parse(filestring, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
					  // Process the parsed CSV data here
					  if (result.data && result.data.length > 0) {
						this.importcsvdata = result.data.map(row => {
							const tag = row.Description.split(" ")
							let product_name_slug = row.Description.replace(`'`, '');
							
							let product_upload_data = {
								upc_code: row.UPC,
								product_description: row.Description,
								pack: row.Pack,
								srp: row.SRP,
								category: row.Category,
								upload_file_id: fileid,
								product_image: "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726",
								product_name_slug: product_name_slug,
								price_label_format: row.PriceLabelFormat ? row.PriceLabelFormat : "",
								price_label_svg: '',
								price_label_value1: '',
								price_label_value2: '',
								price_label_value3: '',
								price_label_highlight: row.PriceLabelHighlight ? row.PriceLabelHighlight : "",
								product_url: row.ProductUrl ? row.ProductUrl : "",
								hide_price_label: row.HidePriceLable ? row.HidePriceLable : "",
								hide_addtolist_label: row.HideAddtolistLabel ? row.HideAddtolistLabel : "",
								hide_productname_label: row.HideProductnameLabel ? row.HideProductnameLabel : "",
								cost: row.Cost ? row.Cost : "",
								store_uid: this.current_store_uid,
								system_notes: "Uploaded using csv from saved-items",
								new_window: row.NewWindow ? row.NewWindow : "",
								autoplay: row.AutoPlay ? row.AutoPlay : "",
								secondery_product_image: [],
								tag: tag ? tag : []
						  	};
							 // Your specific logic for handling the "cols[3]" data
							 if (row.SRP) {
								const srpValue = parseFloat(row.SRP);
								if (!isNaN(srpValue)) {
									product_upload_data.price_label_value1 = srpValue.toFixed(2);
									const srpString = product_upload_data.price_label_value1;
									if (row.SRP.includes('/off')) {
										product_upload_data.price_label_format = "format7";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (row.SRP.includes('Buy')) {
										product_upload_data.price_label_format = "format6";
									} else if (row.SRP.includes('each') || row.SRP.includes('/lb') || row.SRP.includes('pound')) {
										product_upload_data.price_label_format = "format4";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (row.SRP.includes('/') || row.SRP.includes('for')) {
										product_upload_data.price_label_format = "format5";
									} else if (srpString.includes('.')) {
										product_upload_data.price_label_format = "format2";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else {
										product_upload_data.price_label_format = "format1";
									}
								}
							}
							return product_upload_data;
						});
					  }
					},
				});
        // if you want to be more clever...
        let result = this.saved_items_list_temp.filter(o1 => this.importcsvdata.some(o2 => o1.upc_code === o2.upc_code));
        this.duplicates_temp = result;
        if(this.importcsvdata.length == 0)
        {
          this._snackBar.open("No products to import.",'',{
            duration: 1500,
          });
        } 
        if(result.length > 0)
        {
          // this._snackBar.open("Some products already exist in the system.",'',{
          //  duration: 1500,
          // });
          this.open(this.uploadExistModal,'modal-basic-title1'); 


        }
        if(result.length == 0)
        {
          this.importcsvdata.forEach((value,key)=>{
            value.created_at = new Date();
            let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
              const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
              itemRefx2.update({ uid: docRef.id}); 
            });
          });
          this._snackBar.open("Items imported successfully!",'',{
            duration: 1500,
          });
          
        }
      }
    }
  }

  set_current_icon(data)
  {
    
  }


  upload(event,ind) {
    this.image_uploading = true;
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => 
        console.log("INDDDDD--",ind); let tempimg = reader.result;
      // this.imageurl1 = reader.result;
      // this.imageurl2 = reader.result;
    
      reader.readAsDataURL(file);
    
    }

    // create a random id
    const randomId = "digital-circular-"+ new Date().getTime() +  Math.random().toString(36).substring(2);
    // create a reference to the storage bucket location
    this.ref = this.afStorage.ref('uploads/'+randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    this.task =  this.ref.put(event.target.files[0]);

    //this.imageurl = this.task.downloadURL();
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe(url => {

          if(ind == '1')  
          { 
            this.imageurl1 = url; 
            this.product_edit_form.patchValue({
              product_image   :  url,
            });
          }
 
          if(ind == '2')  
          { 
            this.imageurl2 = url; 
            this.product_add_form.patchValue({
              product_image   :  url,
            });
          }
      
          this.image_uploading= false;
        
        });
      })
    ).subscribe(); 


    // this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";
    
  }

  openAddProduct()
  {
     this.show_add_product = true;
     this.current_selected_product = [];
     this.product_add_form.reset();
  }

  trigger_import_csv()
  {
    let element: HTMLElement = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
    element.click();
  }

  trigger_upload_image()
  {
    let element: HTMLElement = document.getElementsByClassName('update_image_upload')[0] as HTMLElement;
    element.click();
  }

  trigger_upload_image_add()
  {
    let element: HTMLElement = document.getElementsByClassName('update_image_upload2')[0] as HTMLElement;
    element.click(); 
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  open(content,title) {
    this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  import_ignore_duplicate()
  {

    this.importcsvdata.forEach((value,key) => {
      let value2 = [];
      value2.push(value);
      let result2 = value2.filter(o1 => this.saved_items_list_temp.some(o2 => o1.upc_code === o2.upc_code));
      if(result2.length == 0)
      {
        //only import unique values thats why storing the unique values here
        let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
          const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
          itemRefx2.update({ uid: docRef.id}); 
        });
      }
    });

    let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
    element.click();

    this._snackBar.open(this.importcsvdata.length + " Products imported successfully!",'',{
            duration: 2000,
          });

  }

  import_update_duplicate()
  {

    this.importcsvdata.forEach((value,key) => {
      
      let item = this.duplicates_temp.find(i => i.upc_code === value.upc_code);
      if(item)
      {

        //this product already exists in the system so update it.
        let update_to_db = this.afs.doc('/master-items/'+item.uid).set(value,{merge:true}).then(docRef => {
        });

      } else {
        value.created_at = new Date();
        //this product is new so create a new record.
        let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
          const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
          itemRefx2.update({ uid: docRef.id}); 
        });

      }
  
    });

    let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
    element.click();

    this._snackBar.open( this.importcsvdata.length +" Products imported successfully!",'',{
      duration: 2000,
    });
  }


  product_update(data)
  {
    const itemRefx= this.afs.doc('/master-items/'+ data.uid).set(data,{merge:true}).then(docRef => {});
    this._snackBar.open("Product details updated successfully!",'',{
      duration: 1500,
    });
  }


  product_store(data)
  {
    if(data.upc_code == "" || data.upc_code == null)
    {
      this._snackBar.open("Error:UPC Code is Required",'',{
        duration: 1500,
      });
      return false;
    }
    if(data.product_description == "" || data.product_description == null)
    {
      this._snackBar.open("Error:Product description is Required",'',{
        duration: 1500,
      });
      return false;
    }
    let temp_store = [];
    temp_store.push(data);
    let result = this.saved_items_list_temp.filter(o1 => temp_store.some(o2 => o1.upc_code === o2.upc_code));
    if(result.length > 0)
    {
      this._snackBar.open("Error: This Product already exists in your Saved Products!",'',{
        duration: 1500,
      });
      return false;
    }

    data.price_label_value1 = "";
    data.price_label_value2 = "";
    data.price_label_value3 = "";
    data.price_label_highlight =  "";
    data.cost = "";
    data.srp  = "";

    data.created_at = new Date();
    data.system_notes = "Created manually using form";
    data.store_uid = this.current_store_uid;
    const itemRefx = this.afs.collection('/master-items').add(data).then(docRef => {
          const itemRefx2= this.afs.doc('/master-items/' + docRef.id);
          itemRefx2.update({ uid: docRef.id}); 
          this.product_add_form.reset();
      });
    this._snackBar.open("Product added successfully!",'',{
      duration: 1500,
    });



  }


  checkbox_select_all_event(event)
  {
    let value = event.target.checked;
    let cnt = this.saved_items_list_temp.length;
    for(let i = 0;i<cnt;i++)
    {
      if(value == true)
      {
        this.checkbox_delete_array[i] = true;
      } else {
        this.checkbox_delete_array[i] = false;
      }
    }
    
  }
  checkbox_single_select_event(event,i)
  {
    if(event.target.checked == true)
    {
      this.checkbox_delete_array[i] = true;
    } else {
      this.checkbox_delete_array[i] = false;
    }
  }

  openDeleteModal()
  {
    if(this.checkbox_delete_array.length  == 0)
    {
      this._snackBar.open("Error: Please select some products to delete!",'',{
        duration: 1500,
      });
      return false;
    }
    this.open(this.deleteModal,'modal-basic-title3'); 
  }

  delete_products()
  {
    let cnt = this.checkbox_delete_array.length;
    for(let d=0;d<cnt;d++)
    {
      let val = this.checkbox_delete_array[d];
      if(val == true)
      {
        this.checkbox_delete_array[d] = 0;
        const itemRefx = this.afs.doc('/master-items/'+ this.saved_items_list_temp[d].uid).delete();
          this.ngOnInit();

      }
    }
    let element: HTMLElement = document.getElementsByClassName('delete_product_modal')[0] as HTMLElement;
    element.click();
  }

  gotoAll()
  {
    this.router.navigate(['/store/all-items']);
  }
  gotoSaved()
  {
    this.router.navigate(['/store/saved-items']);
  }

  save_item(uid)
  {
    let update_to_db = this.afs.doc('/master-items/'+ uid).set({is_saved: true},{merge:true}).then(docRef => {
        });
    this._snackBar.open("Item has been moved to Saved Products!",'',{
      duration: 1500,
    });
  }

  unsave_item(uid)
  {
    let update_to_db = this.afs.doc('/master-items/'+ uid).set({is_saved: false},{merge:true}).then(docRef => {
        });

    this._snackBar.open("Item removed from saved!",'',{
      duration: 1500,
    });
  }


}

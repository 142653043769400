<ng-template #warehouseAddModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title2" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add new Warehouse</h4>
		<button type="button" class="close close_add_warehouse_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" style="background-color:#fff !important;">
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<div class="modal-body">
				<form [formGroup]="create_warehouse_form" (ngSubmit)="submit_warehouse(create_warehouse_form.value)" style="width: 100%;padding: 0 1rem;margin-top:1rem;" >

				<div class="row">
					<div class="col-12">
						<label>Warehouse Name</label>
						<input  placeholder=""  class="form-control" formControlName="warehouse_name">
						<p *ngIf="required_label_warehouse" class="text-danger" style="margin-bottom: 0;font-size: 12px;">Please enter warehouse name</p>
					</div>

					<div class="col-12 text-center" style="margin-top: 1rem;">
						<button type="submit" class="btn btn-primary float-center text-center" style="background:#71b01d;">Submit</button>
					</div>
				</div>
			</form>

			<div class="row" style="margin-top: 1rem;padding: 0 2rem;">
				<label>Warehouses List</label>
				<ul class="list-group" style="width: 100%;height:185px;overflow: auto;">
					<li class="list-group-item" *ngFor="let item of warehouselist" style="margin-bottom:1px;">{{item.warehouse_name}}  <span (click)="delete_warehouse(item.uid)"><img src="assets/imgs/icon-trash.svg" style="    height: 13px;float: right;margin-top: 5px;"></span></li>
					<li class="list-group-item" *ngIf="warehouselist && warehouselist.length == 0" style="background: #f7f7f7;margin-bottom:1px;">No Warehouse added</li>
				</ul>
			</div>
	</div>
	
</ng-template>






<div class="sidebar">
	<div class="dashboard-site-logo"><img src="../../assets/images/Group 3068.png" class="w-100" alt=""></div>

</div>
<div class="mobile-menu d-none">
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<a class="navbar-brand" href="#"> <img src="../../assets/images/Group 3068.png" class="w-100" alt=""></a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button> 

		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mr-auto dashboard-menu">
				<li class="nav-item ">
					<a class="nav-link" href="#"> <img src="../../assets/images/dashboard-icon.png"
							alt=""><span>Dashboard</span></a>
				</li>
				<li class="nav-item ">
					<a class="nav-link" href="#"><img src="../../assets/images/store-owner-icon.svg" alt=""> <span>Store
							Owners </span></a>
				</li>


			</ul>

		</div>
	</nav>
</div>
<div class="admin-navbar">
	<div class="row">
		<div class="col-md-9">
			<div class="admin-panel-headline">
				<span>Admin Panel</span>
			</div>
		</div>
		<div class="col-md-3">
			<div class="logged-in-username text-right">
				<img class="user-icon" src="{{users && users[0]?.photoURL}}" alt="">
				<span  data-toggle="modal" data-target="#exampleModal">{{users && users[0]?.displayName}}</span>
				<a data-toggle="modal"   data-target="#LogOutConfirmation"><img class="logout-icon" src="../../assets/images/logout-icon.svg" (click)="authService.SignOut()" alt=""></a>
			</div>
		</div>
	</div>
</div>


<section class="add-new-store-section">
		
			<form [formGroup]="storeaddForm" (ngSubmit)="store_add(storeaddForm.value);" style="width: 100%;padding: 0 1rem;">
		
	<div class="container-fluid">
		
		<div class="row">
			<div class="BackButton" (click)="backToStore()" style="cursor:pointer;">
				<div class="back-icon cursor-pointer"><img src="../../assets/images/Arrow.png" alt=""></div>
				<div class="add-new-store-headline">
					<h3 class="mb-0">View/Edit Store Details</h3>
				</div>
			</div>
			<div class="col-md-3" style="z-index:2;">
				<div class="basic-detail-form">
					<div class="form">
						<div class="form-item">
							<input type="text" id="username" formControlName="company_name" required />
							<label for="username">Company Name*</label>
						</div>
						<!-- <div class="form-item">
							<input type="text" id="StoreOwnerName" autocomplete="off" formControlName="store_owner_name" required />
							<label for="StoreOwnerName">Store Owner Name*</label>
						</div> -->

						<div class="form-item">
							<ng-select [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="selectedAllGroup" [items]="users_list" bindLabel="displayName" [closeOnSelect]="false" [multiple]="true" bindValue="uid" formControlName="userlist" (change)="select_branch($event)" placeholder="Select User">
								<ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
								<input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}"
								[ngModel] ="item$.selected"/> Select All
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
								<mat-checkbox style="pointer-events: none" color="primary" id="item-{{index}}" [checked]="item$.selected">
									{{item.displayName}}
								</mat-checkbox>
							</ng-template>
							</ng-select>
						</div>

						<!-- <div class="form-item">
							<input type="email" id="EmailId" autocomplete="off" formControlName="email" required />
							<label for="EmailId">Email id*</label>
						</div> -->
						<div class="form-item">
							<input type="text" id="PhoneNo" autocomplete="off" formControlName="phone" required />
							<label for="PhoneNo">Store Phone Number</label>
						</div>

						<div class="form-item" style="width: 100%;">
							<div class="select-access-box mb-0" style="width: 100%;">

								<!-- <select class="form-select" formControlName="warehouse" id="warehouse" (change)="warehouseChange($event.target.value)">
									<option value="">Select Warehouse (if applicable)</option>
									<option value="add">Add new Warehouse</option>
									<option value="Warehouse 1">Warehouse 1</option>
									<option value="Warehouse 2">Warehouse 2</option>
									<option value="Warehouse 3">Warehouse 3</option>
								</select> -->
								<div >
										<ng-select [(ngModel)]="selected_warehouse" formControlName="warehouse" [clearable]="false" placeholder="Select a Warehouse" notFoundText="No warehouse found" class="select_input_noborder"   #warehouseid (change)="warehouseChange()" style="width: 366px;">
											<ng-option [value]="0" disabled= true>Select a Warehouse</ng-option>
											<ng-option [value]="'add'">Add new Warehouse</ng-option>
										   <ng-option *ngFor="let type of warehouselist;let j = index" [value]="type.warehouse_name">{{type.warehouse_name}}</ng-option>
										</ng-select>
								</div>
							</div>
						</div>

						<div class="form-item">
							<input type="text" id="StoreId" autocomplete="off" formControlName="store_id" required />
							<label for="StoreId">Store ID</label>
						</div>
						<div class="form-item">
							<input type="text" id="BranchName" autocomplete="off" formControlName="branch_name" required />
							<label for="BranchName">Branch name </label>
						</div>
						<div class="form-item">
							<input type="number" id="ZipCode" autocomplete="off" formControlName="zip_code" required />
							<label for="ZipCode">ZIP CODE</label>
						</div>
						<div class="form-item">
							<input type="text" id="Address	" autocomplete="off" formControlName="address" required />
							<label for="Address">ADDRESS</label>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">

				<div class="website-menu-form">

					<div class="form mt-0">
						<h5>Website Menu</h5>

					
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m1" formControlName="menu1_name" autocomplete="off" required>
									<label for="m1">Menu Option 1</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu1_link" id="mLink1" autocomplete="off" required >
									<label for="mLink1">Link</label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m2" formControlName="menu2_name" autocomplete="off" required>
									<label for="m2">Menu Option 2</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu2_link" id="mLink2" autocomplete="off" required>
									<label for="mLink2">Link </label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m3" formControlName="menu3_name"  autocomplete="off" required >
									<label for="m3">Menu Option 3</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu3_link" id="mLink3"  autocomplete="off" required>
									<label for="mLink3">Link </label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m4" formControlName="menu4_name" autocomplete="off" required >
									<label for="m4">Menu Option 4</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu4_link"  id="mLink4" autocomplete="off" required >
									<label  for="mLink4">Link </label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m5" formControlName="menu5_name"  autocomplete="off" required>
									<label for="m5">Menu Option 5</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu5_link" id="mLink5" autocomplete="off" required >
									<label  for="mLink5">Link </label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m6" formControlName="menu6_name"  autocomplete="off" required>
									<label for="m6">Menu Option 6</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu6_link" id="mLink6"  autocomplete="off" required >
									<label  for="mLink6">Link </label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m7" formControlName="menu7_name" autocomplete="off" required>
									<label for="m7">Menu Option 7</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" id="mLink7" formControlName="menu7_link" autocomplete="off" required>
									<label for="mLink7" >Link </label>
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control"id="m8" formControlName="menu8_name" autocomplete="off" required >
									<label for="m8">Menu Option 8</label>
								</div>
								<div class="col">
									<input type="text" class="form-control link-form" formControlName="menu8_link" id="mLink8" autocomplete="off" required >
									<label  for="mLink8">Link </label> 
								</div>
							</div>
							<div class="form-row form-item">
								<div class="col">
									<input type="text" class="form-control" id="m9" formControlName="menu9_name"  autocomplete="off" required>
									<label for="m9">Menu Option 9</label>
								</div>
								<div class="col">
									<input type="text" class="form-contro link-form" formControlName="menu9_link" id="mLink9"  autocomplete="off" required>
									<label  for="mLink9">Link </label>
								</div>
							</div>
						
						<!-- <div class="form-item form-group">
							<input class="form-control" type="text" id="Menu1" autocomplete="off" required />
							<label for="Menu1">Menu option 1</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu2" autocomplete="off" required />
							<label for="Menu2">Menu option 2</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu3" autocomplete="off" required />
							<label for="Menu3">Menu option 3</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu4" autocomplete="off" required />
							<label for="Menu4">Menu option 4 </label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu5" autocomplete="off" required />
							<label for="Menu5">Menu option 5</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu6" autocomplete="off" required />
							<label for="Menu6">Menu option 6</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu7" autocomplete="off" required />
							<label for="Menu7">Menu option 7 </label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu8" autocomplete="off" required />
							<label for="Menu8">Menu option 8</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="Menu9" autocomplete="off" required />
							<label for="Menu9">Menu option 9</label>
						</div> -->
					</div>
					<!-- <div class="form mt-3">
						<h5>Price Label Option</h5>
							<div class="form-row" >
								<div class="form-check align-items-center d-flex">
									<input class="mr-2" type="radio" id="circularPriceLabel" formControlName="price_label" value="circular">
									<label class="form-check-label" for="circularPriceLabel">Circular Price Label
										<img src="assets/imgs/label1.svg" alt="Clever Price Label" width="50">
									</label>
								</div>
								<div class="form-check align-items-center d-flex" style="margin-bottom: 05px;">
									<input class="mr-2" type="radio" id="cleverPriceLabel" formControlName="price_label" value="cleaver">
									<label class="form-check-label" for="cleverPriceLabel"> Cleaver Price Label
										<img src="assets/imgs/1format.png" alt="Clever Price Label" width="50">
									</label>
								</div>
						</div>
					</div> -->
				</div>

			</div>
			<!-- <div class="col-md-3">
				<div class="website-menu-link-form">
					<div class="form">
						<div class="form-item form-group">
							<input type="text" id="MenuLink1" autocomplete="off" required />
							<label for="MenuLink1">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink2" autocomplete="off" required />
							<label for="MenuLink2">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink3" autocomplete="off" required />
							<label for="MenuLink3">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink4" autocomplete="off" required />
							<label for="MenuLink4">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink5" autocomplete="off" required />
							<label for="MenuLink5">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink6" autocomplete="off" required />
							<label for="MenuLink6">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink7" autocomplete="off" required />
							<label for="MenuLink7">Link </label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink8" autocomplete="off" required />
							<label for="MenuLink8">Link</label>
						</div>
						<div class="form-item form-group">
							<input class="form-control" type="text" id="MenuLink9" autocomplete="off" required />
							<label for="MenuLink9">Link</label>
						</div>
					</div>
				</div>
			</div> -->
			<div class="col-md-3">

				<div class="access-selection form mt-0">
					<div class="select-access-box" style="margin-bottom: 15px;">
						<div class="social-form-headline mb-2">
							<h5 class="mb-0">Page Access: &nbsp;&nbsp;</h5>
						</div>
						<div  style="margin-bottom: 5px;">
							<input type="checkbox" id="recipePageToggle" formControlName="displayRecipePage" style="margin-right:3px ; margin-bottom: 10px;" />
							<label for="recipePageToggle">Recipe Functionality</label>
						</div>
						
						<div   style="margin-bottom: 5px;">
							<input type="checkbox" id="couponPageToggle" formControlName="displayCouponPage" style="margin-right:3px ; margin-bottom: 10px;" />
							<label for="couponPageToggle">Coupon Functionality</label>
						</div>
						<div  style="margin-bottom: 5px;">
							<input type="checkbox" id="circularPageToggle" formControlName="displayCircularPage" style="margin-right:3px ; margin-bottom: 10px;" />
							<label for="circularPageToggle">Circular Functionality</label>
						</div>
						
						<div>
							<input type="checkbox" id="bundlePageToggle" formControlName="displayBundlePage" style="margin-right:3px ; margin-bottom: 10px;" />
							<label for="bundlePageToggle">Bundle Functionality</label>
						</div>
						<div>
							<input type="checkbox" id="menuPageToggle" formControlName="displayMenuPage" style="margin-right:3px ; margin-bottom: 10px;" />
							<label for="menuPageToggle">Menu Functionality</label>
						</div>
					</div>
					<h5>Access</h5>
					<div class="select-access-box">

						<ng-select formControlName="access_layout" [clearable]="false" placeholder="Layout Access" notFoundText="No Layout found" class="select_input_noborder" style="width: 260px;">
						   <ng-option *ngFor="let type of arrAccessLayout;let j = index" [value]="type.name">{{type.name}}</ng-option>
						</ng-select>
					</div>
					<div class="select-access-box">

						<ng-select formControlName="access_recipe" [clearable]="false" placeholder="Recipe Access" notFoundText="No Layout found" class="select_input_noborder" style="width: 260px;">
							<ng-option *ngFor="let type of arrAccessRecipe;let j = index" [value]="type.name">{{type.name}}</ng-option>
						 </ng-select>
					</div>
					<div class="select-access-box">

						<ng-select formControlName="access_coupon" [clearable]="false" placeholder="Coupons Access" notFoundText="No Layout found" class="select_input_noborder" style="width: 260px;">
							<ng-option *ngFor="let type of arrAccessCoupon;let j = index" [value]="type.name">{{type.name}}</ng-option>
						 </ng-select>
					</div>
					<div class="select-access-box" style="margin-bottom: 15px;">
						<div class="social-form-headline mb-2" >
							<h5 class="mb-0">Clover Payment Credentials : &nbsp;&nbsp;</h5>
						</div>
						<div  style="margin-right:3px ; margin-bottom: 20px;margin-top:20px;width: 258px;" class="form-item">
						 <input type="text" formControlName="merchantId" class="form-control" placeholder="Enter Merchant ID" id="merchantId" style="width: 258px;">
						 <label for="merchantId">Merchant ID</label>
						</div>
						<div   style="margin-right:3px ; margin-bottom: 20px;width: 258px;" class="form-item">
							<input type="password" formControlName="apiToken" class="form-control" placeholder="Enter API Token"  id="apiToken" style="width: 258px;">
							<label for="apiToken">API Token</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-store-button">
			<button type="submit" class="btn">Update</button>
		</div>
	</div>

</form>
</section>


 <!-- Admin-Details-Modal -->
 <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
 aria-hidden="true">
 <div class="modal-dialog modal-dialog-centered" role="document">
   <div class="modal-content ">
	 <div class="modal-header col-md-12 d-flex ">
	   <div class="admin-account-details-headline d-flex align-items-center col-md-6 p-0 ">
		 <span   class="close pl-0 pr-4" data-dismiss="modal" aria-label="Close"><img
			 src="../../assets/images/Arrow.png" alt=""></span>
		 <h5 class="mb-0 ">Admin Account Details</h5>
	   </div>
	   <div class="change-password-button d-flex align-items-center justify-content-end col-md-6 p-0 ">
		 <button data-toggle="modal" (click)="ChangePassword()"  data-target="#changePasswordModel" class="change-pwd-button">Change
		   Password</button>
		   <!-- <button (click)="ChangePassword()" class="change-pwd-button">Change
			 Password</button>
			 <div class="jainil">safnasjofn</div> -->
	   </div>
	 </div>
	 <div class="modal-body">
	   <div class="update-details d-flex justify-content-center flex-column">
		 <span class="text-center pt-3 pb-4"><img src="../../assets/images/avtar-file-upload.png" alt=""></span>
		 <span class="update-name-input pt-3 pb-4"><input type="text" class="form-control update-input"
			 placeholder="Peter White"></span>
		 <span class="d-flex flex-column">
		   <label>Email:</label>
		   <span class="user-email">peter123&#64;email.com</span>
		 </span>
	   </div>
	 </div>
	 <div class="modal-footer d-flex justify-content-center">
	   <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
	   <button type="button" class="btn btn-primary">Update</button>
	 </div>
   </div>
 </div>
</div>
<!--Change Password Model-->
<div class="modal fade" id="changePasswordModel" tabindex="-1" role="dialog" aria-labelledby="changePasswordModel"
 aria-hidden="true">
 <div class="modal-dialog modal-dialog-centered" role="document">
   <div class="modal-content ">
	 <div class="modal-header col-md-12 d-flex ">
	   <div class="admin-account-details-headline d-flex align-items-center col-md-6 p-0 ">
		 <span (click)="closePopup()" class="close pl-0 pr-4" data-dismiss="modal" aria-label="Close"><img
			 src="../../assets/images/Arrow.png" alt=""></span>
		 <h5 class="mb-0 ">Admin Account Details</h5>
	   </div>

	 </div>
	 <div class="modal-body">
	   <div class="change-password-msg">
		 <p>On clicking “Send Reset Password link” button, you will receive a link on your registered
		   email id to change password.</p>
		 <span class="d-flex justify-content-center"><button type="button" class="btn btn-primary">Send Reset
			 Password Link</button></span>
	   </div>
	 </div>

   </div>
 </div>
</div>

<!--Logout Confirmation-->
<div class="modal fade" id="LogOutConfirmation" tabindex="-1" role="dialog" aria-labelledby="LogOutConfirmation"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content ">
	<div class="modal-header col-md-12 d-flex justify-content-center ">
	  <div class="admin-account-details-headline d-flex align-items-center p-0 justify-content-center">
		<!-- <span (click)="closePopup()" class="close pl-0 pr-4 " data-dismiss="modal" aria-label="Close"><img
			src="../../assets/images/Arrow.png" alt=""></span> -->
		<h5 class="mb-0 ">Are you sure you want to logout?</h5>
	  </div>

	</div>
	<div class="modal-body">
	  <div class="change-password-msg">
		<!-- <p>Are you sure you want to logout?</p> -->
	  <div class="confirmation-btn d-flex justify-content-center">
		
		<span class="d-flex  mr-3"  ><button data-dismiss="modal" aria-label="Close" type="button" class="btn btn-primary ">Cancel</button></span>
		<span class="d-flex" ><button (click)="goToLogin()" type="button" class="btn btn-primary ">Continue</button></span>
	   </div>
	   
	  </div>
	</div>

  </div>
</div>
</div>
import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, NgZone, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { finalize, catchError, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentsService } from '../../components.service';
import * as Papa from 'papaparse';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-all-items',
	templateUrl: './all-items.component.html',
	styleUrls: ['./all-items.component.scss']
})
export class AllItemsComponent implements OnInit {

	datasource = [];
	tableColumns: string[] = ['checkbox', 'id', 'upc', 'image', 'product_desc', 'category', 'price', 'action'];
	title = 'appBootstrap';
	saved_items_list: any;
	user: any;
	current_store_uid: any;
	importcsvdata: any = [];
	saved_items_list_temp: any;
	duplicates_temp: any;
	closeResult: any;
	current_selected_product: any;
	imageurl1: any = '';
	imageurl2: any = '';
	ref: any;
	task: any;
	imageSrc: any;
	imageurl: any;
	show_add_product: boolean = true;
	checkbox_delete_array: any = [];
	checkbox_select_all: any;
	categories_list: any;
	loading: boolean = false;
	buttonDisabled = false;
	matchImage: boolean = true;
	product_edit_form: FormGroup;
	product_add_form: FormGroup;
	image_uploading: boolean = false;
	show_icon_results: any;
	icons: any;
	searchClient: SearchClient;
	master_list: any;
	csv_data_new: any = []
	current_unsplash_type: any;
	get_current_store_id: any;
	ghost_mode: boolean = false;
	isDeleteShow: boolean = false
	match_found: boolean;
	isGIF: boolean
	isVideo: any = false
	storeInMaster: boolean = false;
	matchingItems: any;
	add_form_img = ''
	domain_name:string='';
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '5rem',
		minHeight: '5rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		toolbarHiddenButtons: [
			['fontSize'],
			['insertImage'],
			['insertVideo'],
			['fontName'],
			['textColor'],
			['backgroundColor'],
			['unlink'],
			['insertHorizontalRule'],
			['removeFormat'],
			['toggleEditorMode'],
			['subscript'],
			['superscript'],
			['strikeThrough'],
			['underline'],
			['heading'],
			['link']
		]
	}

	search_icon_form: FormGroup;
	@ViewChild('iconSearchModal') iconSearchModal;
	@ViewChild('leadsSort', { static: true }) leadsSort: MatSort;
	@ViewChild('leadsPaginator', { static: true }) leadsPaginator: MatPaginator;
	@ViewChild('uploadExistModal') uploadExistModal;
	@ViewChild('deleteModal') deleteModal;
	@ViewChild('matchdeleteModal') matchdeleteModal;
	@ViewChild('fileInput') fileInput: ElementRef;
	@ViewChild('bundleProduct') bundleProduct!: ElementRef;


	pageSize = 10;
	totalItems: any;
	startRange = 1;
	pageIndex = 0;
	masterPageIndex1 = 0;
	masterPrevPageIndx: any;
	masterTotalItem: any;
	masterFirstItem: any;
	masterLastItem: any;
	filterValue: any;
	filterFlag: boolean;
	filterstoreTotalItem: any;
	oldPageSize: number;
	startIndex: any;
	delete_pro: boolean;
	checkbox_checked_item: any[];
	saved_items_list_temp1: { upc_code: any; product_description: any; product_name_slug: any; pack: any; price_label_format: any; price_label_value1: any; price_label_value2: any; price_label_value3: any; price_label_highlight: any; product_url: any; new_window: any; hide_price_label: any; hide_addtolist_label: any; hide_productname_label: any; cost: any; srp: any; category: any; uid: any; product_image: any; price_label_svg: any; serial: number; is_saved: any; isVideo: any; isBanner: any; autoplay: any; loopvideo: any; bannerText: any; storeInMaster: any; created_at: any; master_uid: any; }[];
	deleteFilteredItems: boolean;
	saved_items_list_csv:any;
	price_label_svg: string;
	export_saved_items_list_csv: any =[];
	csvData: any=[];
	csvProductDescriptions: any =[];
	matchedProducts: any =[];
	unmatchedProducts: any=[];
	bundledList: any[];
	isBundleProduct: any;
	subscriptions: any;
	store_data: any;
	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private http: HttpClient,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private componentService: ComponentsService
	) {
		this.searchClient = algoliasearch(
			environment.algolia.appId,
			environment.algolia.apiKey,
		);

		this.product_edit_form = new FormGroup({
			uid: new FormControl(''),
			master_uid: new FormControl(''),
			product_image: new FormControl(''),
			upc_code: new FormControl(''),
			product_description: new FormControl(''),
			pack: new FormControl(''),
			product_url: new FormControl(''),
			category: new FormControl(''),
			offerLabel: new FormControl(''),
			labelSide: new FormControl('topleft'),
			price_label_format: new FormControl(''),
			new_window: new FormControl(''),
			isBanner: new FormControl(''),
			bannerText: new FormControl(''),
			autoplay: new FormControl(''),
			loopvideo: new FormControl(''),
			storeInMaster: new FormControl(''),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			price_label_value1: new FormControl('0'),
			price_label_value2: new FormControl('0'),
			price_label_value3: new FormControl('0'),
			price_label_svg: new FormControl(''),
			isVideo: new FormControl(false),
			bundleProduct:new FormControl(''),
		});

		this.product_add_form = new FormGroup({
			uid: new FormControl(''),
			product_image: new FormControl(''),
			upc_code: new FormControl(''),
			product_description: new FormControl(''),
			pack: new FormControl(''),
			product_url: new FormControl(''),
			category: new FormControl(''),
			offerLabel: new FormControl(''),
			labelSide: new FormControl('topright'),
			price_label_format: new FormControl(''),
			new_window: new FormControl(''),
			isBanner: new FormControl(''),
			bannerText: new FormControl(''),
			autoplay: new FormControl(''),
			loopvideo: new FormControl(''),
			storeInMaster: new FormControl(''),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			price_label_value1: new FormControl('0'),
			price_label_value2: new FormControl('0'),
			price_label_value3: new FormControl('0'),
			price_label_svg: new FormControl(''),
			isVideo: new FormControl(false),
			isBundleProduct: new FormControl(false),
		});

		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});

	}


	downloadFile(data: any) {
		const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
		const header = Object.keys(data[0]);
		let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
		csv.unshift(header.join(','));
		let csvArray = csv.join('\r\n');

		var blob = new Blob([csvArray], { type: 'text/csv' })
		saveAs(blob, "saved-items-" + new Date().getTime() + ".csv");
	}

	openIconModal(type) {
		this.current_unsplash_type = type;
		this.open(this.iconSearchModal, 'modal-basic-title5');
		//https://api.iconify.design/search?query=dashboard&limit=120
		//https://api.iconify.design/akar-icons/home.svg?color=%2371B01D&height=56
	}

	set_current_image(value) {
		//setting image from unsplash;
		this.matchImage = false
		if (this.current_unsplash_type == 'edit') {
			//setting unsplash image when editing a product
			this.imageurl1 = value;
			this.product_edit_form.patchValue({
				product_image: value,
			});
		}
		if (this.current_unsplash_type == 'add') {
			//setting unsplash image wheen adding a product
			this.imageurl2 = value;
			this.add_form_img = value
			this.product_add_form.patchValue({
				product_image: value,
			});
		}
		let element: HTMLElement = document.getElementsByClassName('close_unsplash_modal')[0] as HTMLElement;
		element.click();
	}


	submit_search_icon(value) {
		this.http
			.get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&order_by=relevant&query=' + value.query + '&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
			.subscribe(res => {
				this.icons = res['results'];
				this.show_icon_results = true;
			});
	}

	async export_csv() {
		await this.savedALLListItem()
		this.downloadFile(this.export_saved_items_list_csv);
		this._snackBar.open("File downloaded successfully!", '', {
			duration: 1500,
		});
	}

	async ngOnInit() {

		this.user = JSON.parse(localStorage.getItem('userrole'));
		//this.current_store_uid = this.user.store_uid;

		let chkghost = localStorage.getItem('ghost_mode');
		if (chkghost) {
			this.ghost_mode = true;
		} else {
			this.ghost_mode = false;
		}
		this.get_current_store_id = localStorage.getItem('current_store_id');
		if (this.get_current_store_id) {
			this.user = JSON.parse(this.get_current_store_id);
		} else {
			this.user = JSON.parse(localStorage.getItem('userrole'));
		}

		this.current_store_uid = this.user.store_uid;

		//get list of master items as we need to compare and store new items if new items are uploaded.
		//if the master already contains the new uploaded item we will ignore it else we will upload it.
		// const querySnapshot: any = this.afs.collection('/master-items').get().toPromise();
		// this.master_list = querySnapshot?.docs?.map((doc: any) => ({
		// 	product_description: doc.data().product_description
		// }));

		this.afs.collection('/lookup-categories').snapshotChanges().subscribe(data => {
			this.categories_list = data.map(e => {
				return {
					uid: e.payload.doc.id,
					value: e.payload.doc.data()['value'],
				};
			});
		});
		 await this.afs.collection('/stores', ref => ref.where('uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
			this.store_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					access_coupon: e.payload.doc.data().access_coupon,
					access_layout: e.payload.doc.data().access_layout,
					access_recipe: e.payload.doc.data().access_recipe,
					company_name_slug: e.payload.doc.data().company_name_slug,
					company_name: e.payload.doc.data().company_name,
					branch_name_slug: e.payload.doc.data().branch_name_slug,
					price_label: e.payload.doc.data().price_label ? e.payload.doc.data().price_label:'circular',
					theme_base_color: e.payload.doc.data().theme_base_color ? e.payload.doc.data().theme_base_color : '#28a745'
				};
			});
		});
		this.masterTotalItem = undefined;
		this.masterLastItem = undefined;
		this.pageIndex = 0;
		this.all_items1();
	}


	async onPageChange(event: any) {
		this.oldPageSize = this.pageSize;
		this.masterPageIndex1 = event.pageIndex;
		this.masterPrevPageIndx = event.previousPageIndex;
		this.pageSize = event.pageSize;
		this.checkbox_delete_array = false;
		this.checkbox_select_all = false;
		if (this.filterValue && this.filterValue !== '') {
			this.masterFirstItem = undefined;
			this.masterLastItem = undefined;
			await this.searchMaster(this.filterValue, event)
		} else {
			if (this.oldPageSize !== this.pageSize) {
				this.pageIndex = 0;
				this.masterFirstItem = undefined;
				this.masterLastItem = undefined;
				await this.all_items('all');
				await this.getTotalMasterItemCount('all');
			} else {
				await this.all_items('all');
				await this.getTotalMasterItemCount('all');
			}
		}

	}
	doFilter = async (value: string) => {
		this.pageIndex = 0;
		this.masterLastItem = undefined
		this.masterFirstItem = undefined
		this.filterValue = value.trim();
		this.filterFlag = true
		this.searchMaster(this.filterValue, "first")
		// this.saved_items_list.filter = value.trim().toLocaleLowerCase();

	}
	// addToBundledList() {
	// 	const productName = this.product_add_form.get('bundleProduct')?.value.trim();
	// 	if (productName && !this.bundledList.includes(productName)) {
	// 	  console.log(this.bundledList, 'bundledList');
	// 	  this.bundledList.push(productName);
	// 	  this.product_add_form.get('bundleProduct')?.reset(); // Clear the input field
	// 	}else{
	// 		this._snackBar.open("Error:Product already present in the bundle", '', {
	// 			duration: 1500,
	// 		});
	// 		console.log("Value",this.bundleProduct.nativeElement.value)
	// 	}
	//   }
	  updateToBundledList() {
		const productName = this.product_edit_form.get('bundleProduct')?.value.trim();
		if (productName && !this.bundledList.includes(productName)) {
		  console.log(this.bundledList, 'bundledList');
		  this.bundledList.push(productName);
		  this.product_edit_form.get('bundleProduct')?.reset(); // Clear the input field
		}else{
			this._snackBar.open("Error:Product already present in the bundle", '', {
				duration: 1500,
			});
			console.log("Value",this.bundleProduct.nativeElement.value)
		}
	  }

	  removeFromBundledList(productName: string) {
		this.bundledList = this.bundledList.filter(product => product !== productName);
	  }
	async searchMaster(value, event) {
		if (this.filterValue !== '') {
			this.deleteFilteredItems=true;
			this.checkbox_checked_item = []
            this.checkbox_delete_array=[]
			try {
				const querySnapshot = await this.afs.collection("/store-items", (ref) => ref
					.where('store_uid', '==', this.current_store_uid)
					.orderBy("product_description", "asc")
					.orderBy('created_at', 'desc')
					.orderBy("uid", "desc")
				).get().toPromise();
				// Extract the data from the query snapshot
				const data = querySnapshot.docs.map(doc => doc.data());
				let i = 0;
					// Filter the data locally based on the search query
				this.saved_items_list_temp = data.filter(item => {
					// Perform case-insensitive search on product_description field
					return ((item as any).product_description &&
						typeof (item as any).product_description === 'string' &&
						(item as any).product_description.toLowerCase().includes(this.filterValue && typeof this.filterValue === 'string' ? this.filterValue.toLowerCase() : '') || (item as any).upc_code.toLowerCase().includes(this.filterValue && typeof this.filterValue === 'string' ? this.filterValue.toLowerCase() : ''))
				});
				this.saved_items_list_temp=await this.saved_items_list_temp.map((data: { [x: string]: any; }) => {
					i++;
					// const data = doc.data();
	
					this.leadsPaginator.length = this.totalItems;
					return {
						upc_code: data["upc_code"],
						product_description: data["product_description"],
						product_name_slug: data["product_name_slug"],
						pack: data["pack"] ? data["pack"] : "",
						price_label_format: data["price_label_format"]
							? data["price_label_format"]
							: "",
						price_label_value1: data["price_label_value1"]
							? data["price_label_value1"]
							: "",
						price_label_value2: data["price_label_value2"]
							? data["price_label_value2"]
							: "",
						price_label_value3: data["price_label_value3"]
							? data["price_label_value3"]
							: "",
						price_label_highlight: data["price_label_highlight"]
							? data["price_label_highlight"]
							: "",
						product_url: data["product_url"] ? data["product_url"] : "",
						new_window: data["new_window"] ? data["new_window"] : "",
						hide_price_label: data["hide_price_label"],
						hide_addtolist_label: data["hide_addtolist_label"],
						hide_productname_label: data["hide_productname_label"],
						cost: data["cost"],
						srp: data["srp"],
						category: data["category"],
						uid: data["uid"],
						product_image: data["product_image"]
							? data["product_image"]
							: "https://via.placeholder.com/30/cecece/cecece",
						price_label_svg: data["price_label_svg"],
						serial: i - 1,
						is_saved: data["is_saved"] ? data["is_saved"] : "",
						isVideo: data["isVideo"] ? data["isVideo"] : "",
						isBanner: data["isBanner"] ? data["isBanner"] : "",
						autoplay: data["autoplay"] ? data["autoplay"] : false,
						loopvideo: data["loopvideo"] ? data["loopvideo"] : false,
						bannerText: data["bannerText"] ? data["bannerText"] : false,
						storeInMaster: data["storeInMaster"] ? data["storeInMaster"] : "",
						created_at: data["created_at"],
						master_uid: data['master_uid'] ? data['master_uid'] : '',
						isBundleProduct: data['isBundleProduct'] ? data['isBundleProduct'] : '',
						bundleProduct: data['bundleProduct'] ? data['bundleProduct'] : '',
	
					};
				});
	

				// Assign the filtered list to MatTableDataSource for display
				this.saved_items_list = new MatTableDataSource(this.saved_items_list_temp);
				// Reassign sorting and pagination
				this.saved_items_list.sort = this.leadsSort;
				this.saved_items_list.paginator = this.leadsPaginator;
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		} else {
			this.all_items1()
			// If the search query is empty, reset to display the original data
			this.saved_items_list = new MatTableDataSource(this.saved_items_list_temp);
			// Reassign sorting and pagination
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
		}
	}


	async getTotalMasterItemCount(value) {
		const valueLower = value.toLocaleLowerCase();
		const valueUpper = value.toLocaleUpperCase();
		const valueArrayLower = valueLower.split(" ");
		const valueArrayUpper = valueUpper.split(" ");
		const combinedArray = valueArrayLower.concat(valueArrayUpper);
		if (value == 'all' || value == '') {
			if (this.masterTotalItem === undefined) {
				try {
					const querySnapshot = this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise()
					this.masterTotalItem = (await querySnapshot).size; // Cache the totalItems
					this.totalItems = this.masterTotalItem;
					// Update the paginator length
					this.leadsPaginator.length = this.totalItems;
				} catch (error) {
					console.error("Error fetching data: ", error);
				}
			} else {
				this.totalItems = this.masterTotalItem;
				// If totalItems is already cached, update the paginator length
				this.leadsPaginator.length = this.totalItems;
			}
		} else {
			const Value = value.trim()
			try {
				const querySnapshot = await this.afs.collection("/store-items", (ref) => ref
					.where('store_uid', '==', this.current_store_uid)
					.where('tag', 'array-contains-any', combinedArray)
					.orderBy("product_description", "asc").orderBy('created_at', 'desc')
					.orderBy("uid", "desc")).get().toPromise();
				this.filterstoreTotalItem = querySnapshot.size; // Cache the totalItems
				this.totalItems = this.filterstoreTotalItem;
				this.leadsPaginator.length = this.totalItems;
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		}
	}

	async all_items1() {
		this.deleteFilteredItems=false;
		this.pageIndex = 0;
		this.checkbox_select_all = false;
		await this.all_items('all');
		await this.getTotalMasterItemCount('all');
	}

	async all_items(value) {
		this.deleteFilteredItems=false;
		this.checkbox_select_all = false;
		this.checkbox_delete_array = [];
		this.checkbox_checked_item = [];
		let query;
		if (
			!this.masterLastItem ||
			this.masterPageIndex1 == this.masterPrevPageIndx
		) {
			this.startRange = this.pageIndex * this.pageSize;
			query = this.afs.collection("/store-items", (ref) => ref.where('store_uid', '==', this.current_store_uid).orderBy("created_at", "desc").orderBy("uid", "desc").limit(this.pageSize));

		} else {
			if (this.masterPageIndex1 > this.masterPrevPageIndx) {
				this.startRange = (this.pageIndex + 1) * this.pageSize;
				query = this.afs.collection("/store-items", (ref) => ref.where('store_uid', '==', this.current_store_uid)
					.orderBy("created_at", "desc")
					.orderBy("uid", "desc")
					.startAfter(this.masterLastItem.created_at, this.masterLastItem.uid)
					.limit(this.pageSize)
				);
			} else {
				this.startRange = (this.pageIndex - 1) * this.pageSize;
				query = this.afs.collection("/store-items", (ref) => ref.where('store_uid', '==', this.current_store_uid)
					.orderBy("created_at", "desc")
					.orderBy("uid", "desc")
					.endBefore(
						this.masterFirstItem.created_at,
						this.masterFirstItem.uid,
					)
					.limitToLast(this.pageSize)
				);
			}
		}
		try {
			const querySnapshot = await query.get().toPromise();
			let i = this.startRange;
			this.saved_items_list_temp = querySnapshot.docs.map((doc) => {
				i++;
				const data = doc.data();

				this.leadsPaginator.length = this.totalItems;
				return {
					upc_code: data["upc_code"],
					product_description: data["product_description"],
					product_name_slug: data["product_name_slug"],
					pack: data["pack"] ? data["pack"] : "",
					price_label_format: data["price_label_format"]
						? data["price_label_format"]
						: "",
					price_label_value1: data["price_label_value1"]
						? data["price_label_value1"]
						: "",
					price_label_value2: data["price_label_value2"]
						? data["price_label_value2"]
						: "",
					price_label_value3: data["price_label_value3"]
						? data["price_label_value3"]
						: "",
					price_label_highlight: data["price_label_highlight"]
						? data["price_label_highlight"]
						: "",
					product_url: data["product_url"] ? data["product_url"] : "",
					new_window: data["new_window"] ? data["new_window"] : "",
					hide_price_label: data["hide_price_label"],
					hide_addtolist_label: data["hide_addtolist_label"],
					hide_productname_label: data["hide_productname_label"],
					cost: data["cost"],
					srp: data["srp"],
					category: data["category"],
					uid: data["uid"],
					product_image: data["product_image"]
						? data["product_image"]
						: "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: data["price_label_svg"],
					serial: i - 1,
					is_saved: data["is_saved"] ? data["is_saved"] : "",
					isVideo: data["isVideo"] ? data["isVideo"] : "",
					isBanner: data["isBanner"] ? data["isBanner"] : "",
					autoplay: data["autoplay"] ? data["autoplay"] : false,
					loopvideo: data["loopvideo"] ? data["loopvideo"] : false,
					bannerText: data["bannerText"] ? data["bannerText"] : false,
					storeInMaster: data["storeInMaster"] ? data["storeInMaster"] : "",
					created_at: data["created_at"],
					master_uid: data['master_uid'] ? data['master_uid'] : '',
					isBundleProduct: data['isBundleProduct'] ? data['isBundleProduct'] : '',
					bundleProduct: data['bundleProduct'] ? data['bundleProduct'] : '',

				};
			});


			const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
			if (lastDoc) {
				this.masterLastItem = {
					product_description: lastDoc.data()?.product_description,
					created_at: lastDoc.data()?.created_at,
					uid: lastDoc.data()?.uid,
					product_image: lastDoc.data()?.product_image,
				};
			}
			const firstDoc = querySnapshot.docs[0];
			if (firstDoc) {
				this.masterFirstItem = {
					product_description: firstDoc.data()?.product_description,
					created_at: firstDoc.data()?.created_at,
					uid: firstDoc.data()?.uid,
					product_image: firstDoc.data()?.product_image,
				};
			}


			this.saved_items_list = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
			this.pageIndex = this.masterPageIndex1;
			this.pageIndex = this.leadsPaginator.pageIndex;
			this.leadsPaginator.length = this.totalItems;

		} catch (error) {
			console.error("Error fetching data: ", error);
		}
		this.delete_pro = false;
	}

	goToAddNewStore() {
		this.router.navigate(['./add-new-store']);
	}

	onEnter(event: KeyboardEvent) {
		const inputValue = this.bundleProduct.nativeElement.value;
	    console.log(inputValue,'inputValue')
		if (inputValue) {
		  this.updateToBundledList();
		  event.preventDefault(); // Prevents form submission on Enter
		}
	  }
	view_product(data) {
		this.show_add_product = false;
		this.current_selected_product = data;
		this.isBundleProduct = data.isBundleProduct
		this.bundledList = data?.bundleProduct ? data?.bundleProduct : '';
		this.imageurl1 = data.product_image + "&size=408&thumbnail=true&palette=RGB";
		this.product_edit_form.patchValue({
			uid: data.uid,
			product_image: data.product_image,
			upc_code: data.upc_code,
			product_description: data.product_description,
			pack: data.pack,
			price_label_format: data.price_label_format,
			category: data.category,
			product_url: data.product_url,
			hide_price_label: data.hide_price_label,
			hide_addtolist_label: data.hide_addtolist_label,
			hide_productname_label: data.hide_productname_label,
			isVideo: data.isVideo,
			master_uid: data?.master_uid,
			product_name_slug: data?.product_name_slug,
			new_window: data?.new_window ? data?.new_window : false,
			autoplay: data?.autoplay ? data?.autoplay : false,
			loopvideo: data?.loopvideo ? data?.loopvideo : false,
			isBanner: data?.isBanner ? data?.isBanner : false,
			bannerText: data?.bannerText ? data?.bannerText : '',
			storeInMaster: data?.storeInMaster ? data?.storeInMaster : false,
			offerLabel: data?.offerLabel ? data?.offerLabel : '',
			labelSide: data?.labelSide ? data?.labelSide : '',
			bundleProduct :data?.bundleProduct ? data?.bundleProduct : '',
			isBundleProduct:data?.isBundleProduct ? data?.isBundleProduct : ''
		});
		if (data.isVideo) {
			this.storeInMaster = true;
		} else {
			this.storeInMaster = false;
		}
		this.product_edit_form.get('bundleProduct')?.reset(); // Clear the input field
		this.isVideo = data.isVideo		
		function scrollToTop() {
			const element = document.querySelector('.digital-circular-headline');
			  if (element) {
				element.scrollIntoView({
				  behavior: 'smooth', // Smooth scroll
				  block: 'start' // Align the top of the element with the top of the scroll area
				});
			  } else {
				console.log('Element with class "digital-circular-headline" not found.');
			  }
			}
		scrollToTop()	
	}
	async getCSVpricelabel(data) {
		// circular price label
		if (data.price_label_format != '') {
			switch (data.price_label_format) {
				case "format1":
					return this.price_label_svg='<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
				case "format2":
					if (data.price_label_value1) {
						return this.price_label_svg= '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div></div></div></div></div></div></div></div>';
					} else {
						return '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></span></div></div></div></div></div></div></div></div>';
					}
				case "format3":
					if (data.price_label_value1) {
						return this.price_label_svg='<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span class=><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>'
					}
				case "format4":
					if (data.price_label_value1) {
						return this.price_label_svg= '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>'
					}
				case "format5":
					if (data.price_label_value2) {
						return this.price_label_svg= '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></span></div></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span id="third" class="cents-amount">' + ' ' + data.price_label_value3 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div></div></div></div></div></div></div></div>'
					}
				case "format6":
					if (data.price_label_value3 == '') {
						return this.price_label_svg= '<div id="format6" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="third-amount"> Free</span></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format6" class="circular-card-deal-wrapper extraSmall withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></div></div></div></div></div></div></div>';
					}
				case "format7":
					if (data.price_label_value1) {
						return this.price_label_svg= '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>'
					}
				default:
					return this.price_label_svg= '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
			}
		} else {
			return this.price_label_svg= ''
		}
	}
	async getCleaverCSVpricelabel(data) {
		// circular price label
		if (data.price_label_format != '') {
			switch (data.price_label_format) {
				case "format1":
				return this.price_label_svg='<div id="format1" class="circular-card-deal-wrapperknife">' +
			  '<div class="top-line" style="position:relative; width:100%; height:100%;">' +
				'<img src="../assets/imgs/knipe.png" alt="Cleaver" ' +
					 'style="width:100%; height:100%; object-fit: cover;" />' +
				// Price container absolutely centered on the image:
				'<div class="price-container" ' +
					 'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;">' +
					'<div class="top-line"> ' +
					  '<span class="dollar-sign" style="color:red; font-weight:bold;  margin-right:1px;">$</span>' +
					 '<span id="dollar" class="dollar-amount" style="color:red;  font-weight:bold;">' + data.price_label_value1 + '</span>' +
				'</div>' +
				'</div>' +
			  '</div>' +
			'</div>';
				case "format2":
					if (data.price_label_value1) {
						return this.price_label_svg = '<div id="format2" class="circular-card-deal-wrapperknife extraSmall" "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;"><div class="top-line"><span class="dollar-sign" style="color:red;  font-weight:bold;">$</span><span id="dollar" style="color:red;  font-weight:bold;" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper" style="font-weight: bold; color: red;"><span id="cent" class="cents-amount" style="color:red;  font-weight:bold;">' + ' ' + data.price_label_value2 + '</span></span></div></div></div></div></div>';
					} else {
						return this.price_label_svg = '<div id="format2" class="circular-card-deal-wrapperknife extraSmall" "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;"><div class="top-line"><span id="cent" style="color:red;  font-weight:bold;" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="cent-sign" style="color:red; font-weight:bold;">¢</span></span></span></div></div></div></div></div>'
					}
				case "format3":
					if (data.price_label_value1) {
						return this.price_label_svg = '<div id="format3" class="circular-card-deal-wrapperknife extraSmall" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><div class="price-unit-wrapper" ><span id="cent" class="cents-amount" >' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/LB</span></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg = '<div id="format3" class="circular-card-deal-wrapperknife extraSmall" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><div class="price-unit-wrapper" ><span class="cent-sign" >¢</span><span class="price-unit">/LB</span></div></div></div></div></div></div>'
					}
				case "format4":
					if (data.price_label_value1) {
						return this.price_label_svg = '<div id="format4" class="circular-card-deal-wrapperknife extraSmall" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><div class="price-unit-wrapper" ><span id="cent" class="cents-amount" >' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/EA</span></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg = '<div id="format4" class="circular-card-deal-wrapperknife extraSmall" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><div class="price-unit-wrapper" ><span class="cent-sign">¢</span><span class="price-unit">/EA</span></div></div></div></div></div></div>'
					}
				case "format5":
					if (data.price_label_value2) {
						return this.price_label_svg = '<div id="format5" class="circular-card-deal-wrapperknife extraSmall"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold;flex-direction:column;"><div class="num-to-cart pricing-num-for "><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for" >for</span></div> <div class="circular-card-price"><div class="top-line"><span class="dollar-sign" >$</span><span id="cent" class="cents-amount" style="">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></span></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg = '<div id="format5" class="circular-card-deal-wrapperknife extraSmall"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold;flex-direction:column;"><div class="num-to-cart pricing-num-for "><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for" >for</span></div><div class="circular-card-price"><div class="top-line"> <span id="third" class="cents-amount" style="">' + ' ' + data.price_label_value3 + '</span><span class="price-unit-wrapper"><span class="cent-sign">¢</span></span></span></div></div></div></div></div></div>'
					}
				case "format6":
					if (data.price_label_value3 == 'Free') {
						return this.price_label_svg = '<div id="format6" class="circular-card-deal-wrapperknife extraSmall"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:64%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold;line-height:0.5;text-transform: uppercase;"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></div></div></div></div></div>';
					} else {
						return this.price_label_svg = '<div id="format6" class="circular-card-deal-wrapperknife extraSmall withNumber"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:64%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold;line-height:0.5;text-transform: uppercase;"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></div></div></div></div></div>';
					}
				case "format7":
					if (data.price_label_value1) {
						return this.price_label_svg = '<div id="format7" class="circular-card-deal-wrapperknife  withNumber extraSmall"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:65%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><span><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div>';
					} else {
						return this.price_label_svg = '<div id="format7" class="circular-card-deal-wrapperknife  withNumber extraSmall"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:65%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><span><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + data.price_label_value2 + '</span><span class="cent-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div>'
					}
				default:
					return this.price_label_svg='<div id="format1" class="circular-card-deal-wrapperknife">' +
			  '<div class="top-line" style="position:relative; width:100%; height:100%;">' +
				'<img src="../assets/imgs/knipe.png" alt="Cleaver" ' +
					 'style="width:100%; height:100%; object-fit: cover;" />' +
				// Price container absolutely centered on the image:
				'<div class="price-container" ' +
					 'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;">' +
					'<div class="top-line"> ' +
					  '<span class="dollar-sign" style="color:red; font-weight:bold;  margin-right:1px;">$</span>' +
					 '<span id="dollar" class="dollar-amount" style="color:red;  font-weight:bold;">' + data.price_label_value1 + '</span>' +
				'</div>' +
				'</div>' +
			  '</div>' +
			'</div>';
				}
		} else {
			return this.price_label_svg= ''
		}
	}

	async import_csv(files: FileList) {
		this.export_saved_items_list_csv=[]
		await this.savedALLListItem()
		if (files && files.length > 0) {
			let file: File = files.item(0);
			let reader: FileReader = new FileReader();
			let fileid = new Date().getTime();      //setting temporary name so that we can know what was the source of the upload
			reader.readAsText(file, 'UTF-8');
			reader.onload = (e) => {
				let filestring: string = reader.result as string;

				Papa.parse(filestring, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
						// Process the parsed CSV data here
						if (result.data && result.data.length > 0) {
							this.importcsvdata = result.data.map(row1 => {
                                const row: any = {};
                                Object.keys(row1).forEach(key => {
                                    const trimmedKey = key?.trim();
									const value = row1[key];
									const trimmedValue = typeof value === 'string' ? value.trim() : value;
									row[trimmedKey] = trimmedValue;           
                                });
								if (row.Description && row.Description.trim()) {
								const tag = row.Description.split(" ")
								let product_name_slug = row.Description.replace(`'`, '');

								let product_upload_data = {
									upc_code: row.UPC,
									product_description: row.Description,
									pack: row.Pack,
									srp: row.SRP,
									category: row.Category,
									upload_file_id: fileid,
									product_image: "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726",
									product_name_slug: product_name_slug,
									price_label_format: row.PriceLabelFormat ? row.PriceLabelFormat : "",
									price_label_svg: '',
									price_label_value1: '',
									price_label_value2: '',
									price_label_value3: '',
									price_label_highlight: row.PriceLabelHighlight ? row.PriceLabelHighlight : "",
									product_url: row.ProductUrl ? row.ProductUrl : "",
									hide_price_label: row.HidePriceLable ? row.HidePriceLable : "",
									hide_addtolist_label: row.HideAddtolistLabel ? row.HideAddtolistLabel : "",
									hide_productname_label: row.HideProductnameLabel ? row.HideProductnameLabel : "",
									cost: row.Cost ? row.Cost : "",
									store_uid: this.current_store_uid,
									system_notes: "Uploaded using csv from saved-items",
									new_window: row.NewWindow ? row.NewWindow : "",
									autoplay: row.AutoPlay ? row.AutoPlay : "",
									secondery_product_image: [],
									tag: tag ? tag : [],
									digital_coupon :false,
								};
								// Your specific logic for handling the "cols[3]" data
								if (row.SRP) {
							            const srpString = row.SRP;
									const srpValue = parseFloat(srpString).toString();

									if (srpString.includes('/off')) {
										product_upload_data.price_label_format = "format7";
										const parts = srpValue.split(".");
										if (parts[0] == '0') {
											product_upload_data.price_label_value2 = parts[1];
										} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
										}
									} else if (srpString.includes('Buy') && srpString.includes('Get')) {
										product_upload_data.price_label_format = "format6";
										const buyGetParts = srpString.split(' ');
										const buyIndex = buyGetParts.indexOf('Buy');
										const getIndex = buyGetParts.indexOf('Get');

										if (buyIndex !== -1 && getIndex !== -1 && getIndex - buyIndex === 2) {
											const buyValue = parseInt(buyGetParts[buyIndex + 1]);
											const getValue = parseInt(buyGetParts[getIndex + 1]);

											if (!isNaN(buyValue) && !isNaN(getValue)) {
												product_upload_data.price_label_value1 = buyValue.toString();
												product_upload_data.price_label_value2 = getValue.toString();

												const forIndex = buyGetParts.indexOf('For');
												if (forIndex !== -1 && forIndex + 1 < buyGetParts.length) {
													const forValue = parseFloat(buyGetParts[forIndex + 1]);
													if (!isNaN(forValue)) {
														product_upload_data.price_label_value3 = forValue.toString();
													}
												}
											}
										}
									} else if (srpString.includes('each') || srpString.includes('pound')) {
										product_upload_data.price_label_format = "format4";
										const parts = srpValue.split(".");
										if (parts[0] == '0') {
											product_upload_data.price_label_value2 = parts[1];
										} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
										}
									} else if (srpString.includes('/lb')) {
										product_upload_data.price_label_format = "format3";
										const parts = srpValue.split(".");
										if (parts[0] == '0') {
											product_upload_data.price_label_value2 = parts[1];
										} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
										}
									} else if (srpString.includes('/') || srpString.includes('for')) {
										product_upload_data.price_label_format = "format5";
										const separator = srpString.includes('/') ? '/' : 'for';
										const parts = srpString.split(separator);
										product_upload_data.price_label_value1 = parts[0];
										if (parts[1].includes('.')) {
											const parts2 = parts[1].split('.');
											product_upload_data.price_label_value2 = parts2[0];
											product_upload_data.price_label_value3 = parts2[1];
										} else {
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (srpString.includes('.')) {
										product_upload_data.price_label_format = "format2";
										const parts = srpValue.split(".");
										if (parts[0] == '0') {
											product_upload_data.price_label_value2 = parts[1];
										} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
										}
									} else {
										product_upload_data.price_label_value1 = srpString;
										product_upload_data.price_label_format = "format1";
									}
								}
									if (this.store_data[0]?.price_label == 'cleaver') {
										this.getCleaverCSVpricelabel(product_upload_data);
									} else {
										this.getCSVpricelabel(product_upload_data);
									}
								product_upload_data.price_label_svg=this.price_label_svg;	
								return product_upload_data;
							}
							});
							this.importcsvdata = this.importcsvdata.filter(data => data !== null && data !== undefined);
							const csv_Data = this.importcsvdata;
							this.csvData = this.importcsvdata;
							this.csvProductDescriptions = csv_Data?.map(item => ({
								product_description: item?.product_description,
								pack: item?.pack
							}));
							this.matchedProducts = this.export_saved_items_list_csv?.filter(currentProduct => 
								this.csvProductDescriptions?.some(csvProduct => {
									const titleMatch = currentProduct?.Description?.trim().toUpperCase() === csvProduct?.product_description?.trim().toUpperCase();
									const packMatch = csvProduct?.pack?.trim().toUpperCase() === currentProduct?.Pack?.trim().toUpperCase();
									return titleMatch && packMatch;
								})
							);							
							
							this.unmatchedProducts = this.csvProductDescriptions?.filter(csvProduct => 
								!this.export_saved_items_list_csv?.some(title => {
									const titleMatch = title?.Description?.trim().toUpperCase() === csvProduct?.product_description?.trim().toUpperCase();
									const packMatch = csvProduct?.pack?.trim().toUpperCase() === title?.Pack?.trim().toUpperCase();					
									return titleMatch && packMatch;
								})
							);							
							const filterArray = [];
							csv_Data?.forEach(item => {
								this.unmatchedProducts?.forEach(description => {
									if (item?.product_description === description?.product_description?.trim()&& item?.pack === description?.pack?.trim()) {
										filterArray.push(item);
									}
								});
							});
							this.importcsvdata = filterArray;
						}
					},
				});
				this.importcsvdata = this.importcsvdata.filter((obj, index, array) =>
					array.findIndex(item => item.product_description === obj.product_description && item.pack===obj.pack) === index
				);
                if(this.unmatchedProducts?.length == 0 && this.csvData?.length>0) {
                	this._snackBar.open("Selected CSV Products are already present in layout!", '', {
                		duration: 1500,
                	});
                }else{       			
				if (this.importcsvdata.length == 0) {
					this._snackBar.open("No products to import.", '', {
						duration: 1500,
					});
				}
				if (this.importcsvdata.length > 0) {
					if (this.importcsvdata.length > 0) {
						this.checkCSVDataIntoList();
					}					
					let element: any = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
					element.value = ''
				}
			}
		}
	}
}

	async addDataToCollection(collectionName, data) {
		const listRef = (await this.afs.collection(`/${collectionName}`).add(data))
		const listId = (await listRef.get()).id
		const updatedList = await listRef.update({ uid: listId })
		const colData = await listRef.get()
		return colData.data()
	}

	async upc_search(search_value) {
		if (search_value == "" || search_value == undefined) {
			return false;
		}
		var APItoken = localStorage.getItem('CHX_token')
		const headerDict = {
			'Authorization': 'EN ' + APItoken,
		}
		const bodyData =
		{
			"OrderBy": "26834672-7c90-4918-9b19-5bd419023b12",
			"Desc": false,
			"AttributeFilterOperator": "And",
			"IdentifierAttribute": [
				"575ad9c5-3da2-472e-ada0-85d227d2cbaf"
			],
			"Identifiers": [
				search_value
			],
			"DataOwner": "07725f7b-db7a-4a89-9ac6-5429a9a51638",
			"PreferredProducts": null,
			"OnHold": false,
			"UserId": "f5a7a747-e05f-4e5d-bc45-2d004c10e26e",
			"Language": "en-US"
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		let result: any
		try {
			result = await this.http
				.post('https://api.syndigo.com/api/marketplace/search?skip=0&take=100&companyId=07725f7b-db7a-4a89-9ac6-5429a9a51638',
					bodyData, requestOptions).toPromise()
			return result?.Results[0]?.Components[0]?.Assets['en-US']?.filter(x => x.AttributeId == "645296e8-a910-43c3-803c-b51d3f1d4a89");

		} catch (error) {
			if (error.status === 401) {
				try {
					await this.componentService.renewToken();
					this.upc_search(search_value)
				} catch (error) {
					console.error("Token renewal failed:", error);
				}
			} else {
				console.error("API error:", error);
			}
		}
	}

	upload(event, ind) {
		this.matchImage = false
		this.image_uploading = true;
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			const reader = new FileReader();
			reader.onload = e =>
				// this.imageurl1 = reader.result;
				// this.imageurl2 = reader.result;
				reader.readAsDataURL(file);

		}

		// create a random id
		const randomId = "digital-menus-" + new Date().getTime() + Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/' + randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task = this.ref.put(event.target.files[0]);

		//this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
			finalize(() => {
				this.ref.getDownloadURL().subscribe(url => {
					this.ref.getMetadata().subscribe(res => {
						if (res.contentType.split('/')[1] == 'gif') {
							this.isGIF = true
							this.isVideo = false
						} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
							this.isVideo = true
							this.isGIF = false
							this.product_add_form.patchValue({
								isVideo: true
							})
							this.product_edit_form.patchValue({
							    isVideo:true
							})
						}
						else {
							this.isGIF = false
							this.isVideo = false
						}
						if (this.isVideo || this.isGIF) {
							this.storeInMaster = true;
						} else {
							this.product_add_form.controls['storeInMaster'].setValue(false);
							this.storeInMaster = false;
						}
					})

					if (ind == '1') {
						this.imageurl1 = url;
						this.product_edit_form.patchValue({
							product_image: url,
						});
					}

					if (ind == '2') {
						this.imageurl2 = url;
						this.add_form_img = url
						this.product_add_form.patchValue({
							product_image: url,
						});
					}

					this.image_uploading = false;

				});
			})
		).subscribe();


		// this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";

	}

	openAddProduct() {
		this.show_add_product = true;
		this.current_selected_product = [];
		this.product_add_form.reset();
	}

	trigger_import_csv() {
		let element: HTMLElement = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
		element.click();
	}
	grid_import_from_csv() {
		this.fileInput.nativeElement.value = ''; // Reset the input element
		this.fileInput.nativeElement.click();
	}

	trigger_upload_image() {
		let element: HTMLElement = document.getElementsByClassName('update_image_upload')[0] as HTMLElement;
		element.click();
	}

	trigger_upload_image_add() {
		let element: HTMLElement = document.getElementsByClassName('update_image_upload2')[0] as HTMLElement;
		element.click();
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	open(content, title) {
		this.modalService.open(content, { ariaLabelledBy: title }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
//fetch store_items for check duplicate product 
async savedListItem() {
	try {
		const snapshot = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('created_at', 'desc')).get().toPromise();
		this.saved_items_list_csv = snapshot.docs.map((e: any) => {
			return {
				upc_code: e.data()['upc_code'],
				product_description: e.data()['product_description'],
				product_name_slug: e.data().product_name_slug,
				pack: e.data()['pack'],
				price_label_format: e.data()['price_label_format'],
				price_label_value1: e.data()['price_label_value1'] ? e.data()['price_label_value1'] : "",
				price_label_value2: e.data()['price_label_value2'] ? e.data()['price_label_value2'] : "",
				price_label_value3: e.data()['price_label_value3'] ? e.data()['price_label_value3'] : "",
				price_label_highlight: e.data()['price_label_highlight'] ? e.data()['price_label_highlight'] : "",
				product_url: e.data()['product_url'] ? e.data()['product_url'] : "",
				hide_price_label: e.data()['hide_price_label'] ? e.data()['hide_price_label'] : "",
				hide_addtolist_label: e.data()['hide_addtolist_label'] ? e.data()['hide_addtolist_label'] : "",
				hide_productname_label: e.data()['hide_productname_label'] ? e.data()['hide_productname_label'] : "",
				cost: e.data()['cost'] ? e.data()['cost'] : "",
				srp: e.data()['srp'] ? e.data()['srp'] : "",
				storeInMaster: e.data().storeInMaster ? e.data().storeInMaster : '',
				category: e.data()['category'] ? e.data()['category'] : "",
				offerLabel: e.data().offerLabel ? e.data().offerLabel : '',
				labelSide: e.data().labelSide ? e.data().labelSide : '',
				uid: e.id,
				product_image: e.data().product_image ? e.data().product_image : 'https://via.placeholder.com/30/cecece/cecece',
				price_label_svg: e.data().price_label_svg ? e.data().price_label_svg : '',
				is_saved: e.data().is_saved ? e.data().is_saved : '',
				store_uid: e.data().store_uid ? e.data().store_uid : '',
				isVideo: e.data().isVideo ? e.data().isVideo : false,
				// created_at  			: e.data()['created_at'],
				master_uid: e.data().master_uid ? e.data().master_uid : '',

			};
		});
	} catch (error) {
		console.error("Error fetching saved list items:", error);
		throw error;
	}
}
async savedALLListItem() {
	try {
		const snapshot = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('created_at', 'desc')).get().toPromise();
		this.export_saved_items_list_csv = snapshot.docs.map((e: any) => {
			return {
				UPC: e.data()['upc_code'] ? e.data()['upc_code'] :"",
				Description: e.data()['product_description'] ? e.data()['product_description'] :"",
				Pack: e.data()['pack'] ? e.data()['pack'] : "",
				SRP: e.data()['srp'] ? e.data()['srp'] : "",
				Category: e.data()['category'] ? e.data()['category'] : "",
			};
		});
	} catch (error) {
		console.error("Error fetching saved list items:", error);
		throw error;
	}
}
	import_ignore_duplicate() {
		// this.checkCSVDataIntoList("ignore")

		let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
		element.click();

	}

	import_update_duplicate() {
		// this.checkCSVDataIntoList("update")

		let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
		element.click();
	}


	async checkCSVDataIntoList() {
		this.loading = true
		await this.savedListItem()
		this.csv_data_new = [];
		let promiseArr = []
		await this.importcsvdata.forEach(async (value, key) => {
			promiseArr.push(
				new Promise(async (resolve, reject) => {
					const index = this.searchClient.initIndex(environment.algolia.indexName);
					let store_result = this.saved_items_list_csv.find(o1 => o1.product_description == value.product_description && o1.store_uid == value.store_uid && o1.pack == value.pack);
					let master_result
					let isFirstIteration = true;
					let product_description = value.product_description
					if (this.componentService.countWords(value.product_description) > 1) {
						while (!master_result && this.componentService.countWords(product_description) > 1) {
							const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', product_description).limit(1)).get().toPromise();
							if (querySnapshot.docs[0]?.data()) {
								this.master_list = await querySnapshot.docs.map((doc: any) => ({
									upc_code: doc.data()['upc_code'],
									product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
									product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
									pack: doc.data()['pack'] ? doc.data()['pack'] : '',
									price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
									price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
									price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
									price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
									price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
									product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
									hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : '',
									hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : '',
									hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : '',
									cost: doc.data()['cost'] ? doc.data()['cost'] : '',
									srp: doc.data()['srp'] ? doc.data()['srp'] : '',
									category: doc.data()['category'] ? doc.data()['category'] : '',
									uid: doc.data()['uid'],
									product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
									price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
									is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
									tag: doc.data()['tag'] ? doc.data()['tag'] : [],
									secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
									upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
									isVideo: doc.data()['isVideo'] ? doc.data()['isVideo'] : false,
									storeInMaster: doc.data().storeInMaster ? doc.data().storeInMaster : '',
									digital_coupon :false,
								}));
								master_result = querySnapshot.docs[0]?.data();
								if (value.product_description !=product_description) {
									value.product_image = master_result.product_image;
									value.secondery_product_image.push({ 'id': Date.now(), 'url': master_result.product_image });
									value.isVideo = master_result.isVideo ? master_result.isVideo : false
									const master_colData: any = await this.addDataToCollection('master-items', value);
								}
								isFirstIteration = false;
							} else {
								var data
								data = await index.search(`"${product_description}"`, {
									filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
									hitsPerPage: 1000
								});

								if (!Array.isArray(data.hits) || data.hits.length === 0) {
									data = await index.search(product_description, {
										filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
										hitsPerPage: 1000
									});
								}

								const product = data.hits
								if (product.length > 0) {
									master_result = product[0]
									value.product_image = master_result.product_image;
									value.secondery_product_image.push({ 'id': Date.now(), 'url': master_result.product_image });
									value.isVideo = master_result.isVideo ? master_result.isVideo : false
									const master_colData: any = await this.addDataToCollection('master-items', value);
								}
							}
							product_description = product_description.replace(/^\S*\s/, '');
						}
					} else {
						const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', value.product_description).limit(1)).get().toPromise();
						if (querySnapshot.docs[0]?.data()) {
							this.master_list = await querySnapshot.docs.map((doc: any) => ({
								upc_code: doc.data()['upc_code'],
								product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
								product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
								pack: doc.data()['pack'] ? doc.data()['pack'] : '',
								price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
								price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
								price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
								price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
								price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
								product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
								hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : '',
								hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : '',
								hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : '',
								cost: doc.data()['cost'] ? doc.data()['cost'] : '',
								srp: doc.data()['srp'] ? doc.data()['srp'] : '',
								category: doc.data()['category'] ? doc.data()['category'] : '',
								uid: doc.data()['uid'],
								product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
								price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
								is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
								tag: doc.data()['tag'] ? doc.data()['tag'] : [],
								secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
								upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
								isVideo: doc.data()['isVideo'] ? doc.data()['isVideo'] : false,
								storeInMaster: doc.data().storeInMaster ? doc.data().storeInMaster : '',
								digital_coupon :false,
							}));
							master_result = querySnapshot.docs[0]?.data();
						} else {
							var data
							data = await index.search(`"${product_description}"`, {
								filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
								hitsPerPage: 1000
							});
							const product = data.hits
							// Add a new property 'numberOfWords' to each product
							const productsWithWordCount = product
								.filter(product => this.componentService.countWords(product.product_description) < 3)
								.map(product => ({
									...product,
									numberOfWords: this.componentService.countWords(product.product_description),
								}));

							const sortedProducts = productsWithWordCount.sort((a, b) => a.numberOfWords - b.numberOfWords);

							if (sortedProducts.length > 0) {
								master_result = sortedProducts[0];
								value.product_image = master_result.product_image;
								value.secondery_product_image.push({ 'id': Date.now(), 'url': master_result.product_image });
								value.isVideo = master_result.isVideo ? master_result.isVideo : false
								const master_colData: any = await this.addDataToCollection('master-items', value);
							}
						}
					}
					value.created_at = new Date()
					value.fromCSV = true;
					let temp;
					if (!store_result) {
						if (!master_result) {
							if (value.upc_code > 9) {
								temp = await this.upc_search(value.upc_code)
								if (temp) {
									value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`
								}
							}
							if(value.product_image != "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726"){
							   value.secondery_product_image.push({ 'id': Date.now(), 'url': value.product_image });
							}
							const master_colData: any = await this.addDataToCollection('master-items', value)
							const temp_master_data = { ...master_colData }
							temp_master_data.master_uid = master_colData.uid;
							delete temp_master_data.secondery_product_image
							temp_master_data.fromCSV = true;
							const colData = await this.addDataToCollection('store-items', temp_master_data)
						} else {
							master_result.store_uid = this.current_store_uid
							master_result.created_at = new Date()
							if (master_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
								value.product_image = master_result.product_image
								value.isVideo = master_result.isVideo ? master_result.isVideo : false
								value.storeInMaster = master_result.storeInMaster ? master_result.storeInMaster : ''
								value.product_url = master_result.product_url? master_result.product_url :""
							} else {
								if (value.upc_code > 9) {
									temp = await this.upc_search(value.upc_code)
									if (temp) {
										value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
										master_result.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`
										master_result.secondery_product_image.push({ 'id': temp[0].Value, 'url': `https://assets.edgenet.com/${temp[0].Value}?fileType=png` })
										const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
									}
								}
							}
							delete value.secondery_product_image
							value.master_uid = master_result.uid?master_result.uid :''
							const colData = await this.addDataToCollection('store-items', value)
						}
					} else {
						if (!master_result) {
							store_result.created_at = new Date()
							if (store_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
								value.product_image = store_result.product_image
								value.secondery_product_image.push({ 'id': Date.now(), 'url': store_result.product_image })
								value.isVideo = store_result?.isVideo ? store_result.isVideo : false;
								value.storeInMaster = store_result.storeInMaster ? store_result.storeInMaster : ''
								value.product_url = store_result.product_url? store_result.product_url :""
							} else {
								if (value.upc_code > 9) {
									temp = await this.upc_search(value.upc_code)
									if (temp) {
										value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
										value.secondery_product_image.push({ 'id': temp[0].Value, 'url': `https://assets.edgenet.com/${temp[0].Value}?fileType=png` })
										store_result.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
										const store_colData: any =await this.afs.doc('/store-items/' + store_result.uid).set(store_result, { merge: true })
									}
								}
							}
							const colData: any = await this.addDataToCollection('master-items', value)
						} else {
							if (store_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
								value.product_image = store_result.product_image
								value.isVideo = store_result?.isVideo ? store_result.isVideo : false
								value.storeInMaster = store_result.storeInMaster ? store_result.storeInMaster : ''
								value.product_url = store_result.product_url? store_result.product_url :""
								value.master_uid = master_result.uid?master_result.uid :''
							} else {
								if (master_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
									value.product_image = master_result.product_image
									value.isVideo = master_result.isVideo ? master_result.isVideo : false
									value.storeInMaster = master_result.storeInMaster ? master_result.storeInMaster : ''
									value.product_url = master_result.product_url? master_result.product_url :""
								} else {
									if (value.upc_code > 9) {
										temp = await this.upc_search(value.upc_code)
										if (temp) {
											value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
											value.secondery_product_image.push({ 'id': temp[0].Value, 'url': `https://assets.edgenet.com/${temp[0].Value}?fileType=png` })
											store_result.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
											const store_colData: any = await this.afs.doc('/store-items/' + store_result.uid).set(store_result, { merge: true })
										}
									}
								}
							}
							delete value.secondery_product_image;
							value.master_uid = master_result.uid?master_result.uid :''
							const store_colData: any = await this.afs.doc('/store-items/' + store_result.uid).set(value, { merge: true })
						}
					}
					resolve("Done!!")
				})
			)
		})
		await Promise.all(promiseArr); 
		// setTimeout(() => {
		this.ngOnInit()
		this.loading =false
		// }, 2000);
	}
	convertFacebookWatchLink(link) {
		const regex = /^https:\/\/www\.facebook\.com\/watch\/\?v=(\d+)$/i;
		const match = link.match(regex);
		if (match) {
			const videoId = match[1];
			
			const canonicalUrl = `https://www.facebook.com/facebook/videos/${videoId}/`;
			
			return canonicalUrl;
		}else{
			return link
		}

	}
	async product_update(data) {
		console.log(data,"data")
		this.buttonDisabled = true;

		if (this.isGIF || !this.isVideo) {
			data.isVideo = false;
		}

		if (!data.isBanner) {
			data.bannerText = '';
		}
		if (data.upc_code == "" || data.upc_code == null) {
			this._snackBar.open("Error:UPC Code is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.offerLabel != '' && (data.labelSide == "" || data.upc_code == null)) {
			this._snackBar.open("Error:Offer Label Position is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.product_description == "" || data.product_description == null) {
			this._snackBar.open("Error:Product description is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		} else {
			data.product_description = data.product_description.trim()
		}
		if(this.bundledList){
			data.bundleProduct = this.bundledList
		}		
		if(this.isBundleProduct){
			data.isBundleProduct = this.isBundleProduct
		}
		data.product_name_slug = data?.product_description?.replace(`'`, '');
		data.tag = data?.product_description.split(" ")
		let product_url = ''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube-nocookie.com" || domain?.hostname == "www.youtube.com") {
				data.isVideo = 'youtube'
				var ytid = data.product_url.match(/\/embed\/([\w\-]{11,})/)[0];
				ytid = ytid.replace('/embed/', '');
				data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
			}
			if (domain?.hostname == "www.facebook.com") {
				data.isVideo = "facebook"
		    	data.product_url=await this.convertFacebookWatchLink(data?.product_url)
		    	const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
		    	const encodedURL = encodeURIComponent(data?.product_url);
		    	const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
		    	data.product_url=data?.product_url
				this.domain_name='facebook'
		    } 
		}

		const itemRefx = this.afs.doc('/store-items/' + data.uid).set(data, { merge: true })
		if (data.isVideo == 'youtube' || data.isVideo == 'facebook' || data.isVideo == true) {
			if (data.storeInMaster) {
				if (data.master_uid == '' || data.master_uid == null) {
					data.created_at = new Date();
					const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
						const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
						data.master_uid = docRef.id
						itemRefx2.update({ uid: docRef.id });
					})
				} else if (data.master_uid) {
					const itemRefx2 = await this.afs.doc('/master-items/' + data.master_uid).get().toPromise();
					const master_old_data: any = itemRefx2.data();
					const master_data = { ...data, secondery_product_image: master_old_data.secondery_product_image || [] }
					delete master_data.uid
					if (!this.matchImage) {
						if (data.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_data.secondery_product_image.push({ 'id': Date.now(), 'url': data?.product_image })
						}
					}
					if (this.user?.role != 'admin' && this.current_selected_product.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						delete master_data.product_image
					}
					const master_product_data = await this.afs.doc('master-items/' + data.master_uid).set(master_data, { merge: true })
				}
			} else {
				if (data.master_uid != '' && data.master_uid != null) {
					await this.afs.doc('/master-items/' + data.master_uid).delete().then(() => {
						data.master_uid = '';
						// Perform additional actions if needed
					})
					.catch((error) => {
							console.error('Error deleting product: ', error);
						});
					}
				}
		} else {
			const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', data.product_description)).get().toPromise();
			this.master_list = querySnapshot.docs.map((doc: any) => ({
				upc_code: doc.data()['upc_code'],
				product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
				product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
				pack: doc.data()['pack'] ? doc.data()['pack'] : '',
				price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
				price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
				price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
				price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
				price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
				product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
				hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : '',
				hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : '',
				bundleProduct: doc.data()['bundleProduct'] ? doc.data()['bundleProduct'] : '',
				hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : '',
				cost: doc.data()['cost'] ? doc.data()['cost'] : '',
				srp: doc.data()['srp'] ? doc.data()['srp'] : '',
				category: doc.data()['category'] ? doc.data()['category'] : '',
				uid: doc.data()['uid'],
				product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
				price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
				is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
				tag: doc.data()['tag'] ? doc.data()['tag'] : [],
				secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
				upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
			}));
			let master_result = querySnapshot.docs[0]?.data();
			if (master_result) {
				if (!this.matchImage) {
					this.matchImage = true
					if (data?.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						if (master_result.product_image == 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_result.product_image = data?.product_image
						}
						master_result.secondery_product_image.push({ 'id': Date.now(), 'url': data.product_image })
					}
					master_result.upc_code = data.upc_code
					const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
				}
			} else {
				if (data.product_image == "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726") {
					data.secondery_product_image = []
				} else {
					data.secondery_product_image = [{ 'id': Date.now(), 'url': data?.product_image }]
				}
				const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					data.master_uid = docRef.id
					itemRefx2.update({ uid: docRef.id });
					delete data.secondery_product_image
				})
			}
		}
		this.buttonDisabled = false;
		this._snackBar.open("Product details updated successfully!", '', {
			duration: 1500,
		});
	}

	async product_store(data) {
		this.buttonDisabled = true;
		if (this.isGIF || !this.isVideo) {
			data.isVideo = false;
		}
		if (data.upc_code == '' || data.upc_code == null) {
			this._snackBar.open('Error:UPC Code is Required', '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.offerLabel != '' && (data.labelSide == "" || data.upc_code == null)) {
			this._snackBar.open("Error:Offer Label Position is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.product_description == '' || data.product_description == null) {
			this._snackBar.open('Error:Product description is Required', '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		} else {
			data.product_description = data.product_description.trim()
		}
		data.isBundleProduct = false;
		if (!data.isBanner) {
			data.bannerText = '';
		}
		if (data.product_url) {
			data.isVideo = true;
		}
		const temp_store = [];
		temp_store.push(data);
		await this.savedListItem();
		const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', data.product_description).limit(1)).get().toPromise();
		this.master_list = querySnapshot.docs.map((doc: any) => ({
			upc_code: doc.data()['upc_code'],
			product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
			product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
			pack: doc.data()['pack'] ? doc.data()['pack'] : '',
			price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
			price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
			price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
			price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
			price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
			product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
			hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : '',
			hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : '',
			hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : '',
			cost: doc.data()['cost'] ? doc.data()['cost'] : '',
			srp: doc.data()['srp'] ? doc.data()['srp'] : '',
			category: doc.data()['category'] ? doc.data()['category'] : '',
			uid: doc.data()['uid'],
			product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
			price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
			is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
			tag: doc.data()['tag'] ? doc.data()['tag'] : [],
			secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
			upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
		}));
		let master_result = querySnapshot.docs[0]?.data();
		if(master_result){
			data.master_uid=master_result.uid
		}
		if (Array.isArray(this.saved_items_list_csv)) {
		const result = this.saved_items_list_csv?.filter(o1 => temp_store?.some(o2 => o1.upc_code === o2.upc_code));
		const result2 = this.saved_items_list_csv?.filter(o1 => temp_store?.some(o2 => o1.product_description === o2.product_description && o1?.pack === o2?.pack));
		if ((result2?.length > 0)) {
			this._snackBar.open('Error: This Product already exists in your Saved Products!', '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
	}


		data.cost = "";
		data.srp = "";
		data.product_name_slug = data?.product_description?.replace(`'`, '');
		if (data?.product_image) {
			data.secondery_product_image = [{ 'id': Date.now(), 'url': data?.product_image }]
		} else {
			data.product_image = "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726"
			data.secondery_product_image = []
		}
		data.tag = data?.product_description.split(" ")
		data.created_at = new Date();
		data.system_notes = "Created manually using form";
		data.store_uid = this.current_store_uid;
		

		let product_url = ''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube.com") {
				data.isVideo = "youtube"
				var ytid = data.product_url.match(/[\w\-]{11,}/)[0];
				data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
				this.domain_name='youtube'
			}
			if (domain?.hostname == "www.facebook.com") {
				data.isVideo = "facebook"
				data.product_url=await this.convertFacebookWatchLink(data?.product_url)
				const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
				const encodedURL = encodeURIComponent(data?.product_url);
				
				const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url=data?.product_url
				this.domain_name='facebook'
			}
		}

		if (data.storeInMaster) {
			if (master_result) {
				const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(data, { merge: true })
			}else{
		   const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
			   const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
			   data.master_uid = docRef.id
			   itemRefx2.update({ uid: docRef.id });
			   delete data.secondery_product_image
		   })
	   }
   }

		if (!data.isVideo) {
			if (master_result) {
				if (!this.matchImage) {
					this.matchImage = true
					if (data?.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						if (master_result.product_image == 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_result.product_image = data?.product_image
						}
						master_result.secondery_product_image.push({ 'id': Date.now(), 'url': data.product_image })
					}
					const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
				}
			} else {
				const master_data = { ...data };
				delete master_data.offerLabel
				delete master_data.labelSide
				const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					data.master_uid = docRef.id
					itemRefx2.update({ uid: docRef.id });
					delete data.secondery_product_image
				})
			}
		}

		const product_data = await this.afs.collection('store-items').add(data).then(docRef => {
			const itemRefx2 = this.afs.doc('/store-items/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			this.product_add_form.reset({
				hide_price_label: false,
				hide_addtolist_label: false,
				hide_productname_label: false,
			});
			this.show_add_product = false;
			this.add_form_img = ''
			this.imageurl2 = ''
		});
		this.buttonDisabled = false
		this._snackBar.open('Product details added successfully!', '', {
			duration: 1500,
		});
		this.masterTotalItem = undefined;
		this.masterLastItem = undefined;
		this.pageIndex = 0;
		await this.all_items1();
	}

	// checkbox_select_all_event(event) {
	// 	this.checkbox_delete_array = [];
	// 	this.checkbox_select_all = event.target.checked;
	// 	let value = event.target.checked;
	// 	let cnt = this.saved_items_list_temp.length;
	// 	let startIndex = this.leadsPaginator.pageIndex * this.leadsPaginator.pageSize;
	// 	let endIndex = startIndex + this.leadsPaginator.pageSize;
	// 	for (let i = startIndex; i < endIndex; i++) {
	// 		if (value == true) {
	// 			this.checkbox_delete_array[i] = true;
	// 		} else {
	// 			this.checkbox_delete_array[i] = false;
	// 		}
	// 	}
	// 	if (value) {
	// 		this.isDeleteShow = true
	// 	} else {
	// 		this.isDeleteShow = false
	// 	}

	// }
	checkbox_select_all_event(event, item) {
		this.checkbox_checked_item = [];
		let serial
		if (item._paginator) {
			serial = item._paginator.pageIndex * this.pageSize
		} else {
			serial = this.startIndex
		}
		let value = event.target.checked;
		let cnt = this.saved_items_list_temp.length;
		if (value == true) {
			this.checkbox_select_all = event.target.checked;
			for (let batchStart = serial; batchStart < serial+this.pageSize && batchStart < serial+this.saved_items_list_temp.length; batchStart++) {
				this.checkbox_checked_item[batchStart] = true
			}
			for (let batchStart = 0; batchStart < this.saved_items_list_temp.length; batchStart++) {
				this.checkbox_delete_array[batchStart] = true;
			}
			if (value) {
				this.isDeleteShow = true
			} else {
				this.isDeleteShow = false
			}
		} else {
			this.checkbox_select_all = event.target.checked;
			this.checkbox_delete_array = []
			this.checkbox_checked_item = []
		}
	}
	checkbox_single_select_event(event, i, serial) {
		if (event.target.checked == true) {
			this.checkbox_delete_array[i] = true;
			this.checkbox_checked_item[serial] = true
		} else {
			this.checkbox_delete_array[i] = false;
			this.checkbox_checked_item[serial] = false
		}
		const temparr = this.checkbox_delete_array.filter(i => i === true)
		if (temparr.length > 0) {
			this.isDeleteShow = true
		} else {
			this.isDeleteShow = false
		}
	}



	openDeleteModal() {
		if (this.checkbox_delete_array.length == 0) {
			this._snackBar.open("Error: Please select some products to delete!", '', {
				duration: 1500,
			});
			return false;
		} else {
			this.check_product_in_grid()
		}
	}

	async check_product_in_grid() {
		this.loading = true;
		this.match_found = false;

		const storeData = await this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise();
		const storeDataNoad = await this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise();
		const BundleData = await this.afs.collection('/bundles', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise();
		const menuData = await this.afs.collection('/menus', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise();

		const layoutsData = [...storeData.docs, ...storeDataNoad.docs, ...BundleData.docs, ...menuData.docs];
		let currentDataTemp
		let currentDataSaved


		for (const layout of layoutsData) {
			const layoutData: any = layout.data();
			if(layoutData?.is_circular){
				if (Array.isArray(layoutData?.layout_json_temp)) {
					currentDataTemp = layoutData
				}else{
					currentDataTemp = await this.componentService.getTempLayout(layoutData?.layout_json_temp)
				}
				
				if (Array.isArray(layoutData?.layout_json_saved)) {
					currentDataSaved = layoutData
				}else{
					currentDataSaved = await this.componentService.getSavedLayout(layoutData?.layout_json_saved)
				}

			}else if(layoutData?.is_menu){
				if (Array.isArray(layoutData?.menu_json_temp)) {
					currentDataTemp = layoutData
				}else{
					currentDataTemp = await this.componentService.getMenuTempLayout(layoutData?.menu_json_temp)
				}
				
				if (Array.isArray(layoutData?.menu_json_saved)) {
					currentDataSaved = layoutData
				}else{
					currentDataSaved = await this.componentService.getMenuSavedLayout(layoutData?.menu_json_saved)
				}
			}else if(layoutData?.is_bundle){
				if (Array.isArray(layoutData?.bundle_json_temp)) {
					currentDataTemp = layoutData
				}else{
					currentDataTemp = await this.componentService.getBundleTempLayout(layoutData?.bundle_json_temp)
				}
				
				if (Array.isArray(layoutData?.bundle_json_saved)) {
					currentDataSaved = layoutData
				}else{
					currentDataSaved = await this.componentService.getBundleSavedLayout(layoutData?.bundle_json_saved)
				}
			}
			if (this.checkMatch(layoutData?.layout_json) || this.checkMatch(currentDataSaved?.layout_json_saved) || this.checkMatch(currentDataTemp?.layout_json_temp) || this.checkMatch(layoutData?.menu_json) || this.checkMatch(currentDataSaved?.menu_json_saved) || this.checkMatch(currentDataTemp?.menu_json_temp) || this.checkMatch(layoutData?.bundle_json) || this.checkMatch(currentDataSaved?.bundle_json_saved) || this.checkMatch(currentDataTemp?.bundle_json_temp)) {
				this.match_found = true;
				break;
			}
		}

		this.loading = false;

		if (this.match_found) {
			this.open(this.matchdeleteModal, 'modal-basic-title3');
		} else {
			this.open(this.deleteModal, 'modal-basic-title3');
		}
	}

	private checkMatch(arrayToCheck) {
		if (!Array.isArray(arrayToCheck)) {
			return false; // Not an array, so no match
		}
		const cnt = this.checkbox_delete_array.length;
		for (let d = 0; d < cnt; d++) {
			if (this.saved_items_list_temp[d] && arrayToCheck.some(item => item.id === this.saved_items_list_temp[d].uid)) {
				return true; // Match found
			}
		}
		return false; // No match found
	}
	


	async delete_products(val) {
		let element: HTMLElement = document.getElementsByClassName('delete_product_modal')[0] as HTMLElement;
		element.click();
		this._snackBar.open('Please wait while the products are being deleted successfully!', '', {
			duration: 2000,
		});
		this.loading = true
		const delete_item_ids = [];
		if(this.deleteFilteredItems){
			for (let d = 0; d < this.checkbox_checked_item.length; d++) {
				if (this.checkbox_checked_item[d] && this.saved_items_list_temp[d]) {
					let delete_item_id = this.saved_items_list_temp[d].uid;
					delete_item_ids.push(delete_item_id);
					this.checkbox_checked_item[d] = 0;
					const itemRefx = await this.afs.doc('/store-items/' + delete_item_id).delete();
				}
			}
		}else{
			for (let d = 0; d < this.checkbox_delete_array.length; d++) {
				if (this.checkbox_delete_array[d] && this.saved_items_list_temp[d]) {
					let delete_item_id = this.saved_items_list_temp[d].uid;
					delete_item_ids.push(delete_item_id);
					this.checkbox_delete_array[d] = 0;
					const itemRefx = await this.afs.doc('/store-items/' + delete_item_id).delete();
				}
			}
		}

		if (val == "grid") {
			this.product_remove_grid(delete_item_ids)
		}
	
		this.checkbox_select_all = false;
		this.isDeleteShow = false
		this._snackBar.open("Products deleted successfully!",'',{
			duration: 1500,
		  });		
		  await this.ngOnInit();
		this.masterPageIndex1 = 0;
		this.masterPrevPageIndx = 0
		this.checkbox_delete_array = [];
		this.checkbox_checked_item = [];
		this.checkbox_select_all = false;
		this.loading = false
	}

	async product_remove_grid(delete_item_ids) {
		try {
			this.loading = true;

			const layoutsCollection = this.afs.collection('/layouts');
			const layoutsQuerySnapshot = await layoutsCollection.ref.where('store_uid', '==', this.current_store_uid).get();

			const updatePromises = [];

			layoutsQuerySnapshot.forEach(async docSnapshot => {
				const data: any = docSnapshot.data();
				 let currentDataTemp
				 let currentDataSaved
				 if (Array.isArray(data?.layout_json_temp)) {
					 currentDataTemp = data
				 }else{
				 	currentDataTemp = await this.componentService.getTempLayout(data?.layout_json_temp)
				 }
			 
				 if (Array.isArray(data?.layout_json_saved)) {
					currentDataSaved = data
				  }else{
				   currentDataSaved = await this.componentService.getSavedLayout(data?.layout_json_saved)
				  }
				const updatedArray = data.layout_json.filter(item => !delete_item_ids.includes(item.id));
				const updatedArray_temp = Array.isArray(currentDataTemp.layout_json_temp) ? currentDataTemp.layout_json_temp.filter(item => !delete_item_ids.includes(item.id)): [];
                const updatedArray_saved = Array.isArray(currentDataSaved.layout_json_saved) ? currentDataSaved.layout_json_saved.filter(item => !delete_item_ids.includes(item.id)): [];

                // Push promises into updatePromises array based on conditions
              

				if (Array.isArray(data?.layout_json_temp)) {
                    updatePromises.push(
                        docSnapshot.ref.update({
                            layout_json_temp: updatedArray_temp
                        })
                    );
                }else{
					await updatePromises.push(this.componentService.setTempLayout(updatedArray_temp, data.layout_json_temp));
				}
                
				if (Array.isArray(data?.layout_json_saved)) {
                    updatePromises.push(
                        docSnapshot.ref.update({
                            layout_json_saved: updatedArray_saved,
                        })
                    );
                }else{
					await updatePromises.push(this.componentService.setSavedLayout(updatedArray_saved, data.layout_json_saved));
				}
                
                // Always update layout_json
                updatePromises.push(
                    docSnapshot.ref.update({
                        layout_json: updatedArray,
                    })
                );
			});   
                // Assuming the end of your forEach loop is here

			// Wait for all update operations to complete
			await Promise.all(updatePromises);

			const layoutsNoadCollection = this.afs.collection('/layouts-noad');
			const layoutsNoadQuery = await layoutsNoadCollection.ref.where('store_uid', '==', this.current_store_uid).get();

			const updateNoadPromises = []

			layoutsNoadQuery.forEach(async docSnapshot => {
				const data: any = docSnapshot.data();
				let currentDataTemp
				let currentDataSaved
				
			  if (Array.isArray(data?.layout_json_temp)) {
				   currentDataTemp = data
			   }else{
				currentDataTemp = await this.componentService.getTempLayout(data?.layout_json_temp)
			   }
				 
			   if (Array.isArray(data?.layout_json_saved)) {
				   currentDataSaved = data
			   }else{
				currentDataSaved = await this.componentService.getSavedLayout(data?.layout_json_saved)
			   }
				const updatedArray = data.layout_json.filter(item => !delete_item_ids.includes(item.id));
				const updatedArray_temp = Array.isArray(currentDataTemp.layout_json_temp) ? currentDataTemp.layout_json_temp.filter(item => !delete_item_ids.includes(item.id)): [];
                const updatedArray_saved = Array.isArray(currentDataSaved.layout_json_saved) ? currentDataSaved.layout_json_saved.filter(item => !delete_item_ids.includes(item.id)) : [];
								
				if (typeof data?.layout_json_temp === "string") {
						await updateNoadPromises.push(this.componentService.setTempLayout(updatedArray_temp, data.layout_json_temp));
					} else {
						updateNoadPromises.push(
							docSnapshot.ref.update({
								layout_json_temp: updatedArray_temp
							})
						);
					}
					
					if (typeof data?.layout_json_saved === "string") {
						await updateNoadPromises.push(this.componentService.setSavedLayout(updatedArray_saved, data.layout_json_saved));
					} else {
						updateNoadPromises.push(
							docSnapshot.ref.update({
								layout_json_saved: updatedArray_saved,
							})
						);
					}
					
					// Always update layout_json
					updateNoadPromises.push(
						docSnapshot.ref.update({
							layout_json: updatedArray,
						})
					);
				});   

			await Promise.all(updateNoadPromises);

			const bundlesCollection = this.afs.collection('/bundles');
			const bundlesQuerySnapshot = await bundlesCollection.ref.where('store_uid', '==', this.current_store_uid).get();

			const updateBundlePromises = [];

			bundlesQuerySnapshot.forEach(async docSnapshot => {
				const data: any = docSnapshot.data();
				 let currentDataTemp
				 let currentDataSaved
				 if (Array.isArray(data?.bundle_json_temp)) {
					 currentDataTemp = data
				 }else{
				 	currentDataTemp = await this.componentService.getBundleTempLayout(data?.bundle_json_temp)
				 }
			 
				 if (Array.isArray(data?.bundle_json_saved)) {
					currentDataSaved = data
				  }else{
				   currentDataSaved = await this.componentService.getBundleSavedLayout(data?.bundle_json_saved)
				  }
				const updatedArray = data.bundle_json.filter(item => !delete_item_ids.includes(item.id));
				const updatedArray_temp = Array.isArray(currentDataTemp.bundle_json_temp) ? currentDataTemp.bundle_json_temp.filter(item => !delete_item_ids.includes(item.id)): [];
                const updatedArray_saved = Array.isArray(currentDataSaved.bundle_json_saved) ? currentDataSaved.bundle_json_saved.filter(item => !delete_item_ids.includes(item.id)): [];

                // Push promises into updateBundlePromises array based on conditions
              

				if (Array.isArray(data?.bundle_json_temp)) {
                    updateBundlePromises.push(
                        docSnapshot.ref.update({
                            bundle_json_temp: updatedArray_temp
                        })
                    );
                }else{
					await updateBundlePromises.push(this.componentService.setBundleTempLayout(updatedArray_temp, data.bundle_json_temp));
				}
                
				if (Array.isArray(data?.bundle_json_saved)) {
                    updateBundlePromises.push(
                        docSnapshot.ref.update({
                            bundle_json_saved: updatedArray_saved,
                        })
                    );
                }else{
					await updateBundlePromises.push(this.componentService.setBundleSavedLayout(updatedArray_saved, data.bundle_json_saved));
				}
                
                // Always update layout_json
                updateBundlePromises.push(
                    docSnapshot.ref.update({
                        bundle_json: updatedArray,
                    })
                );
			});   
                // Assuming the end of your forEach loop is here

			// Wait for all update operations to complete
			await Promise.all(updateBundlePromises);
 
			// menu 
			const menusCollection = this.afs.collection('/menus');
			const menusQuerySnapshot = await menusCollection.ref.where('store_uid', '==', this.current_store_uid).get();

			const updateMenuPromises = [];

			menusQuerySnapshot.forEach(async docSnapshot => {
				const data: any = docSnapshot.data();
				 let currentDataTemp
				 let currentDataSaved
				 if (Array.isArray(data?.menu_json_temp)) {
					 currentDataTemp = data
				 }else{
				 	currentDataTemp = await this.componentService.getMenuTempLayout(data?.menu_json_temp)
				 }
			 
				 if (Array.isArray(data?.menu_json_saved)) {
					currentDataSaved = data
				  }else{
				   currentDataSaved = await this.componentService.getMenuSavedLayout(data?.menu_json_saved)
				  }
				const updatedArray = data.menu_json.filter(item => !delete_item_ids.includes(item.id));
				const updatedArray_temp = Array.isArray(currentDataTemp.menu_json_temp) ? currentDataTemp.menu_json_temp.filter(item => !delete_item_ids.includes(item.id)): [];
                const updatedArray_saved = Array.isArray(currentDataSaved.menu_json_saved) ? currentDataSaved.menu_json_saved.filter(item => !delete_item_ids.includes(item.id)): [];

                // Push promises into updateMenuPromises array based on conditions
              

				if (Array.isArray(data?.menu_json_temp)) {
                    updateMenuPromises.push(
                        docSnapshot.ref.update({
                            menu_json_temp: updatedArray_temp
                        })
                    );
                }else{
					await updateMenuPromises.push(this.componentService.setMenuTempLayout(updatedArray_temp, data.menu_json_temp));
				}
                
				if (Array.isArray(data?.menu_json_saved)) {
                    updateMenuPromises.push(
                        docSnapshot.ref.update({
                            menu_json_saved: updatedArray_saved,
                        })
                    );
                }else{
					await updateMenuPromises.push(this.componentService.setMenuSavedLayout(updatedArray_saved, data.menu_json_saved));
				}
                
                // Always update layout_json
                updateMenuPromises.push(
                    docSnapshot.ref.update({
                        menu_json: updatedArray,
                    })
                );
			});   
                // Assuming the end of your forEach loop is here

			// Wait for all update operations to complete
			await Promise.all(updateMenuPromises);
			this.loading = false;
		} catch (error) {
			console.error(`Error removing array elements from documents: ${error}`);
			this.loading = false;
		}
	}

	gotoAll() {
		this.router.navigate(['/store/all-items']);
	}
	gotoSaved() {
		this.router.navigate(['/store/saved-items']);
	}

	save_item(uid) {
		//THIS FUNCTION IS FOR SAVING THE ITEM. IT WILL MAKE THE IS_SAVED PARAMETER IN SAVED ITEM TO TRUE
		const escapedUid = CSS.escape(uid);
		const saveGrayImage = document.querySelector(`#${escapedUid}`);
        const currentSrc = saveGrayImage.getAttribute('src');
        if (currentSrc.includes('save-grey')) {
		let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: true }, { merge: true }).then(docRef => {
		});
            saveGrayImage.setAttribute('src', '../assets/imgs/save-green.svg');
        	this._snackBar.open("Item has been moved to Saved Products!", '', {
        		duration: 1500,
        	});
        } else {
			let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: false }, { merge: true }).then(docRef => {
			});
            saveGrayImage.setAttribute('src', '../assets/imgs/save-grey.svg');
        	this._snackBar.open("Item removed from saved!", '', {
        		duration: 1500,
        	});
         }
    }

	unsave_item(uid) {
		//THIS FUNCTION IS FOR UNSAVING THE ITEM. IT WILL MAKE THE IS_SAVED PARAMETER IN STORE ITEMS COLLECTION TO FALSE
		const escapedUid = CSS.escape(uid);
		const saveGrayImage = document.querySelector(`#${escapedUid}`);
        const currentSrc = saveGrayImage.getAttribute('src');
        if (currentSrc.includes('save-grey')) {
			let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: true }, { merge: true }).then(docRef => {
			});
            saveGrayImage.setAttribute('src', '../assets/imgs/save-green.svg');
        	this._snackBar.open("Item has been moved to Saved Products!", '', {
        		duration: 1500,
        	});
        } else {
		let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: false }, { merge: true }).then(docRef => {
		});
            saveGrayImage.setAttribute('src', '../assets/imgs/save-grey.svg');
        	this._snackBar.open("Item removed from saved!", '', {
        		duration: 1500,
        	});
         }
    }

	showStoreInMaster(data, update = false) {
		if (data != '' && data != null) {
			this.storeInMaster = true;
		} else {
			if (update) {
				this.product_edit_form.controls['storeInMaster'].setValue(false);
			} else {
				this.product_add_form.controls['storeInMaster'].setValue(false);
				this.storeInMaster = false;
			}
		}
	}

	async autoComplete(value) {
		const index = this.searchClient.initIndex(environment.algolia.indexName);
		try {
			var data
			data = await index.search(value, {
				hitsPerPage: 10,
				page: 0,
				filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
				attributesToRetrieve: ['product_description', 'product_image', 'upc_code','isVideo','storeInMaster'],
			});
			this.matchingItems = data.hits
		}
		catch (error) {
			console.error('Error searching Algolia:', error);
		}
	}

	selectItem(item) {
	    if(item.isVideo){
		  this.isVideo=true;
	    }
		if(item.storeInMaster){
			this.storeInMaster=true;
		}
		this.imageurl2 = item.product_image;

		this.add_form_img = item.product_image
		this.product_add_form.patchValue({
			product_image: item.product_image,
			product_description: item.product_description,
			upc_code: item.upc_code,
			isVideo:item.isVideo,
			storeInMaster:item.storeInMaster
		});
		this.matchingItems = undefined
		this.matchImage = true
	}

	showEditor_add() {
		// Check the current value of the checkbox
		const isBannerChecked = this.product_add_form.get('isBanner').value;
	}


}

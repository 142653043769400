import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrl: './broadcast.component.scss'
})
export class BroadcastComponent implements OnInit {
  accessToken: any;
  private subscriptions: Subscription[] = [];
  tokens: any[];
  broadcastMsg: any;
  broadcastForm: FormGroup;
  store_data: any;
  layout_type: any = ''; // 2 values - grid or page
  apiUrl = "https://fcm.googleapis.com/v1/projects/digital-menus-pro-6b7bd/messages:send";
  ghost_mode: boolean;
  get_current_store_id: string;
  user: any;
  categories_list: any;
  user_data: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    public authService: AuthService,
    private afs: AngularFirestore,
    private http: HttpClient,
    private fns: AngularFireFunctions,
    private modalService: NgbModal,
    public afAuth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.broadcastForm = this.fb.group({
      message: ['', Validators.required]
    });

  }

  async ngOnInit(): Promise<void> {

    const chkghost = localStorage.getItem('ghost_mode');
    if (chkghost) {
      this.ghost_mode = true;
    } else {
      this.ghost_mode = false;
    }
    this.get_current_store_id = localStorage.getItem('current_store_id');
    if (this.get_current_store_id) {
      this.user = JSON.parse(this.get_current_store_id);
    } else {
      this.user = JSON.parse(localStorage.getItem('userrole'));
    }
    this.user_data = JSON.parse(localStorage.getItem('userrole'));
    this.get_current_store_id = this.user.store_uid;
    this.subscriptions[this.subscriptions.length] = await this.afs.collection('/lookup-categories').snapshotChanges().subscribe((data: any) => {
      this.categories_list = data.map(e => {
        return {
          uid: e.payload.doc.id,
          value: e.payload.doc.data().value,
        };
      });
    });
    this.subscriptions[this.subscriptions.length] = await this.afs.collection('/stores', ref => ref.where('uid', '==', this.get_current_store_id)).snapshotChanges().subscribe((data: any) => {
      this.store_data = data.map(e => {
        return {
          uid: e.payload.doc.id,
          company_name_slug: e.payload.doc.data().company_name_slug,
          company_name: e.payload.doc.data().company_name,
          theme_base_color: e.payload.doc.data().theme_base_color ? e.payload.doc.data().theme_base_color : '#28a745'
        };
      });


    });


    await this.fns.httpsCallable('getAccessToken')({}).toPromise()
      .then(response => {
        this.accessToken = response.access_token
      })
      .catch(error => {
        console.error('Error fetching access token:', error); // Handle the error
      });

    this.subscriptions[this.subscriptions.length] = await this.afs.collection('userTokens', ref => ref.where('storeUid', '==', this.store_data[0].uid).where('isLoggedIn', '==', 'no').where('link', '==', 'https://store.digitalmenuspro.com'))
      .snapshotChanges().subscribe(async snapshot => {
        this.tokens = [];

        snapshot.forEach(change => {
          if (change.payload.doc.exists) {
            const tokenData: any = change.payload.doc.data();

            if (tokenData.token) {
              console.log("Extracted Token:", tokenData.token); // ✅ Check extracted token
              this.tokens.push(tokenData.token);
            }
          }
        });

        snapshot.forEach(change => {
          if (change.payload.doc.exists) {
            const tokenData: any = change.payload.doc.data();
            if (tokenData.token) {
              this.tokens.push(tokenData.token);
            }
          }
        });

        if (this.tokens?.length > 0) {
          const callable1 = this.fns.httpsCallable('subscribeToTopic')({
            tokens: this.tokens,

            topics: `SentBroadcastNotification${this.store_data[0].company_name_slug}`  // ✅ Append store name
          }).toPromise().then(response => {
            console.log(response, "response");
          });


        }
      });
  }

  async sendBroadcast(): Promise<void> {
    if (this.broadcastForm.invalid) {
      this._snackBar.open('Message cannot be empty!', 'Close', { duration: 3000 });
      return;
    }

    if (!this.store_data || this.store_data.length === 0) {
      this._snackBar.open('Store data not loaded!', 'Close', { duration: 3000 });
      return;
    }

    this.modalService.dismissAll();

    const topicName = `SentBroadcastNotification${this.store_data[0].company_name_slug}`;
    const body = {
      "message": {
        "topic": `SentBroadcastNotification${this.store_data[0].company_name_slug}`, // ✅ Ensure store name is included
        "notification": {
          "body": this.broadcastMsg,
          "title": "DMP Notification",
        },
        "webpush": {
          "fcm_options": {
            "link": `https://store.digitalmenuspro.com/${this.store_data[0].company_name_slug}`
          },
          "headers": {
            "Urgency": "high"
          },
          "notification": {
            "body": this.broadcastMsg,
            "requireInteraction": "true",
            "badge": "/badge-icon.png",
            "silent": "false"
          },
          "data": {
            "link": `https://store.digitalmenuspro.com/${this.store_data[0].company_name_slug}`
          }
        }
      }
    };

    this.sendNotification(this.accessToken, body).toPromise()
      .then(response => {
        console.log('Notification sent:', response);
        this.broadcastForm.reset()
      })
      .catch(error => {
        console.error('Error sending notification:', error);
        console.log(error?.response);
      });
  }


  sendNotification(privateKey: string, body: any) {
    console.log(body, privateKey, "body");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${privateKey}`,
      'Content-Type': 'application/json',
    });
    return this.http.post(this.apiUrl, body, { headers });
  }


}

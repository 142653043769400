<div class="content">
	<section class="store-information-section">
		<form [formGroup]="store_form" (ngSubmit)="update_store_info(store_form.value);">

			<div class="container-fluid p-0">
				<div class="store-info-headline d-flex justify-content-between mb-4">
					<h4>Store Information</h4>
					<button type="submit" class="btn btn-success">Update Store Information</button>
				</div>
				<div class="row">
					<div class="col-md-4">
						<div class="logo-for-website-box">
							<div class="logo-box-headline mb-4">
								<h4>Logo for website Header</h4>
							</div>
							<div class="image-upload">
								<div class="upload-box">
									<label for="file-input">
										<p *ngIf="showCompressBlock">Original Image</p>
										<!-- <img src="../../assets/images/upload-rectangle.png"
											style="width:291px;height: 58px;border:1px solid #76b100;"
											*ngIf="!store_data" /> -->
										<img id="logotest" src="{{imagesrc}}"
											style="width:500px;height: auto;border:1px solid #76b100;"
											*ngIf="store_data">
										</label>
										<input id="file-input" type="file" accept="image/*"
										(change)="onFileInput($event)" />
										<!-- <input id="file-input" type="file" accept="image/*" (change)="upload($event,'1')" /> -->
										<i *ngIf="imagesrc" class="fa fa-times-circle delete-icon" (click)="deleteImage('store_logo')"></i>
									</div>
									<label for="file-input" class="img-icon">
									<img src="../../assets/images/logo-upload.png" />
								</label>
								<div *ngIf="showCompressBlock">
									<input type="range" min="1" (change)="onResizing($event)" max="100" value="100"
										id="resizingRange" />
									<p>Compressed Image</p>
									<img id="compressedImage" src=""
										style="width:500px;height: auto;border:1px solid #76b100;max-width: 50%;">
								</div>
							</div>

							<div class="upload-logo-instruction d-flex">
								<span><img src="../../assets/images/Group 3113.png" alt=""></span>
								<div class="d-flex flex-column pl-2">
									<p>Above image should include logo and Store name on right side</p>
									<p>Image background should be preferably white <br> Image size - <span
											id="size"></span></p>
									<p>Max file size - 2 MB</p>
									<button type="button" class="btn btn-success"
										(click)="handleUpload()">Upload Logo</button>
								</div>
							</div>

							<div class="logo-box-headline mb-4" style="margin-top: 30px;">
								<h4>Icon For PWA APP</h4>
							</div>
							<div class="img-upload-icon" style="margin-top: 20px">
								<div class="upload-box">
									<label for="file-input-icon">
										<p *ngIf="showCompressBlock">Original Image</p>
										<!-- <img src="../../assets/images/upload-rectangle.png"
											style="width:291px;height: 58px;border:1px solid #76b100;"
											*ngIf="!store_data" /> -->
										<img id="logotest_icon" src="{{imagesrc_icon}}"
											style="width:500px;height: auto;border:1px solid #76b100;"
											*ngIf="store_data">
									</label>
									<input id="file-input-icon" type="file" accept="image/*"
									(change)="onFileInput_icon($event)" />
									<i *ngIf="imagesrc_icon" class="fa fa-times-circle delete-icon" (click)="deleteImage('icons')"></i>
								</div>
								<label for="file-input-icon" class="img-icon">
									<img src="../../assets/images/logo-upload.png" />
								</label>
								
								<!-- <div *ngIf="showCompressBlock" >
									<input type="range" min="1" (change)="onResizing($event)" max="100" value="100" id="resizingRange" />
									<p>Compressed Image</p>
									<img  id="compressedImage" src="" style="width:500px;height: auto;border:1px solid #76b100;max-width: 50%;">
								</div> -->
							</div>
							<div class="upload-logo-instruction d-flex">
								<span><img src="../../assets/images/Group 3113.png" alt=""></span>
								<div class="d-flex flex-column pl-2">
									<p>Above icon should include only store icon</p>
									<p>Icon background should be preferably white <br> Image size - <span
											id="size"></span></p>
									<p>Max file size - 2 MB</p>
									<p>Icon's minimum size should be 512x512.</p>
									<button type="button" class="btn btn-success"
									(click)="handleUpload_icon()">Upload PWA Icon</button>
								</div>
							</div>
						</div>
						<div class="color-box">
							<div class="color-box-headline mb-4">
								<h4>Color Theme</h4>
							</div>
							<div class="color-picker">
								<div class="form-check pl-0 pb-4">
									<span class="mb-2 d-block" style="color:#828282;">Pick color or Enter Hex code <br>
										for Branch Name</span>
									<label class="form-check-label d-flex align-items-center" for="flexRadioDefaultx">
										<input type="color" class="form-control w-50"
											formControlName="branch_name_color" maxlength="6"
											style="    width: 50px !important;height: 50px;padding:2px;"
											(change)="color_change($event,'branch_name_color')"> <span
											class="mr-3 ml-3"></span>
										<span><input type="text" class="form-control w-50"
												formControlName="branch_name_color" maxlength="7"
												(keyup)="color_input_change($event,'branch_name_color')"></span>
									</label>
								</div>
							</div>
							<div class="color-picker">
								<div class="form-check pl-0 pb-4">
									<span class="mb-2 d-block" style="color:#828282;">Pick color or Enter Hex
										code</span>
									<label class="form-check-label d-flex align-items-center" for="flexRadioDefaultx">
										<input type="color" class="form-control w-50" formControlName="theme_base_color"
											maxlength="6" style="    width: 50px !important;height: 50px;padding:2px;"
											(change)="color_change($event,'theme_base_color')"> <span
											class="mr-3 ml-3"></span>
										<span><input type="text" class="form-control w-50"
												formControlName="theme_base_color" maxlength="7"
												(keyup)="color_input_change($event,'theme_base_color')"></span>
									</label>
								</div>
							</div>
							<div class="text-color-content">
								<p>Text & Icons Color scheme for <br>
									Main Header & Footer</p>
							</div>
							<div class="text-color-picker">
								<div class="form-check">
									<input class="form-check-input" type="radio" name="theme_text_color"
										id="flexRadioDefault1" value="#000000" formControlName="theme_text_color">
									<label class="form-check-label" for="flexRadioDefault1">
										<img src="../../assets/images/black-text-color-box.png"
											style="width:49px;height:47px;" alt=""> <span class="mr-3 ml-3">#</span>
										<span><strong>000000</strong></span>
									</label>
								</div>

								<div class="form-check">
									<input class="form-check-input" type="radio" name="theme_text_color"
										id="flexRadioDefault2" value="#ffffff" formControlName="theme_text_color">
									<label class="form-check-label" for="flexRadioDefault2">
										<img src="../../assets/images/white-color-box.png" alt=""> <span
											class="mr-3 ml-3">#</span> <span><strong>FFFFFF</strong></span>
									</label>
								</div>
							</div>
						</div>
						<div class="banner-image">
							<div class="banner-box-headline mb-4">
							  <h4>Print Banner Image</h4>
							</div>
							<div class="image-upload">
								<div class="upload-box">
									<label for="bannerFileInput">
										<p *ngIf="showCompressBlock">Original Image</p>
										<!-- <img src="../../assets/images/upload-rectangle.png"
											style="width:291px;height: 58px;border:1px solid #76b100;"
											*ngIf="!store_data" /> -->
										<img id="logotest" src="{{banner_image}}"
											style="width:500px;height: auto;border:1px solid #76b100;"
											*ngIf="store_data">
										</label>
										<input id="bannerFileInput" type="file" accept="image/*"
										(change)="onFileInput_banner_image($event)" />
										<!-- <input id="bannerFileInput" type="file" accept="image/*" (change)="upload($event,'1')" /> -->
										<i *ngIf="banner_image" class="fa fa-times-circle delete-icon" (click)="deleteImage('banner_image')"></i>
									</div>
									<label for="bannerFileInput" class="img-icon">
										<img src="../../assets/images/logo-upload.png" />
									</label>
							</div>

							<div class="upload-logo-instruction d-flex">
								<div class="d-flex flex-column pl-2">
									<button type="button" class="btn btn-success"
										(click)="handleUploadBannerImage()">Upload Banner Image</button>
								</div>
							</div>
						  </div>							  
					</div>
					<div class="col-md-8">
						<div class="store-info-forms-list">
							<div class="store-form">

								<div class="form-group">
									<label for="formGroupExampleInput">Company Name*</label>
									<input type="text" class="form-control form-gray" id="formGroupExampleInput"
										formControlName="company_name" readonly>
								</div>
								<!-- <div class="form-group">
									<label for="formGroupExampleInput2">Branch Name*</label>
									<input type="text" class="form-control form-gray" id="formGroupExampleInput2"
										formControlName="branch_name" readonly>
								</div> -->
								<!-- <div class="form-group">
									<label for="formGroupExampleInput2">Email</label>
									<input type="email" class="form-control form-gray" id="formGroupExampleInput2"
										formControlName="email" readonly>
								</div> -->
								<div class="form-group">
									<label for="formGroupExampleInput2">Address <br> <img
											src="../../assets/images/i-icon.png" alt=""> <span>It will open via
											Maps</span></label>
									<input type="text" class="form-control" formControlName="address"
										id="formGroupExampleInput2">
								</div>
								<div class="form-group">
									<label for="formGroupExampleInput2">Contact No. <br> <img
											src="../../assets/images/i-icon.png" alt=""> <span>It will appear on
											site</span></label>
									<input type="text" class="form-control" formControlName="phone"
										id="formGroupExampleInput2">
								</div>



							</div>
							<div class="social-media-handle-form">
								<div class="social-form-headline mb-3 mt-5">
									<h4 class="mb-0">Social Media Handles &nbsp;&nbsp;</h4>
									<span> <img src="../../assets/images/i-icon.png" alt=""> &nbsp; <span style="color: red;">IMPORTANT:</span> Add https:// before the URL for it to appear on the site; if left empty, it won’t appear on the site.
									</span>
								</div>

								<div class="form-group">
									<label for="formGroupExampleInput">Facebook URL</label>
									<input type="text" class="form-control" formControlName="social_facebook_url" placeholder="https://facebook.com/username"
										id="formGroupExampleInput">
								</div>
								<div class="form-group">
									<label for="formGroupExampleInput2">Instagram URL</label>
									<input type="text" class="form-control " id="formGroupExampleInput2" placeholder="https://instagram.com/username"
										formControlName="social_instagram_url">
								</div>
								<div class="form-group">
									<label for="formGroupExampleInput2">Youtube URL</label>
									<input type="email" class="form-control " id="formGroupExampleInput2" placeholder="https://youtube.com/username"
										formControlName="social_youtube_url">
								</div>
								<div class="form-group">
									<label for="formGroupExampleInput2">Twitter URL</label>
									<input type="text" class="form-control" id="formGroupExampleInput2" placeholder="https://twitter.com/username"
										formControlName="social_twitter_url">
								</div>




							</div>
							<!-- <div class="social-media-handle-form">
								<div class="social-form-headline mb-3 mt-5">
									<h4 class="mb-0">Page Access Control &nbsp;&nbsp;</h4>
								</div>
								<div class="form-group">
									<input type="checkbox" id="recipePageToggle" formControlName="disableRecipePage" style="margin-right:20px ; margin-bottom: 10px;" />
									<label for="recipePageToggle">Disable Recipe Page</label>
								</div>
								
								<div class="form-group">
									<input type="checkbox" id="couponPageToggle" formControlName="disableCouponPage" style="margin-right:20px ; margin-bottom: 10px;" />
									<label for="couponPageToggle">Disable Coupon Page</label>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</form>
	</section>
</div>
<canvas #canvas style="display: none;"></canvas>
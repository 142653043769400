<div class="sidebar">
	<div class="dashboard-site-logo"><img src="../../assets/images/Group 3068.png" class="w-100" alt=""></div>
</div>

<div class="mobile-menu d-none">
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<a class="navbar-brand" href="#"> <img src="../../assets/images/Group 3068.png" class="w-100" alt=""></a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button> 

		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mr-auto dashboard-menu">
				<li class="nav-item ">
					<a class="nav-link" href="#"> <img src="../../assets/images/dashboard-icon.png"
							alt=""><span>Dashboard</span></a>
				</li>
				<li class="nav-item ">
					<a class="nav-link" href="#"><img src="../../assets/images/store-owner-icon.svg" alt=""> <span>Store
							Owners </span></a>
				</li>


			</ul>

		</div>
	</nav>
</div>
<div class="admin-navbar">
	<div class="row">
		<div class="col-md-9">
			<div class="admin-panel-headline">
				<span>Admin Panel</span>
			</div>
		</div>
		<div class="col-md-3">
			<div class="logged-in-username text-right">
				<img class="user-icon" src="{{users && users[0]?.photoURL}}" alt="">
				<span  data-toggle="modal" data-target="#exampleModal">{{users && users[0]?.displayName}}</span>
				<a data-toggle="modal"   data-target="#LogOutConfirmation"><img class="logout-icon" src="../../assets/images/logout-icon.svg" (click)="authService.SignOut()" alt=""></a>
			</div>
		</div>
	</div>
</div>


<section class="add-new-store-section">
		
	<form [formGroup]="useraddForm" (ngSubmit)="user_add(useraddForm.value);" style="width: 100%;padding: 0 1rem;">	
        <div class="container-fluid">
            <div class="row">
                <div class="BackButton" (click)="backToUser()" style="cursor:pointer;">
                    <div class="back-icon cursor-pointer"><img src="../../assets/images/Arrow.png" alt=""></div>
                    <div class="add-new-store-headline">
                        <h3 class="mb-0">Add New User</h3>
                    </div>
                </div>
                <div class="col-md-3" style="z-index:2;">
                    <div class="basic-detail-form">
                        <div class="form">
                            <div class="form-item">
                                <input type="text" id="username" formControlName="displayName" required />
                                <label for="username">User Name*</label>
                                <span class="text-danger"
                                *ngIf="(registerFormControl.displayName.touched || submitted) && registerFormControl.displayName.errors?.required">
                                Name is required
                            </span>
                            </div>
                            <div class="form-item" style="width: 367px;">
                                
                                <ng-select bindLabel="Select Group" formControlName="userRole"
									[clearable]="false" placeholder="Select User Role" notFoundText="No Role Found"
									[(ngModel)]="isUser" (change)="handle_user($event)"  #userRoleId required>
									<ng-option *ngFor="let role of user_role" [value]="role.id">
										{{role.role}}</ng-option>
								</ng-select>
                            </div>
                            <div class="form-item">
                                <input type="email" id="EmailId" autocomplete="off" formControlName="email" required />
                                <label for="EmailId">Email id*</label>
                                <span class="text-danger" *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">Email is required
                                </span>
                                <span class="text-danger" *ngIf="registerFormControl.email.touched && registerFormControl.email.errors?.email"> Enter a valid email address
                                </span>
                            </div>
                            <div class="form-item">
                                <input type="password" id="password" autocomplete="off" formControlName="password" required />
                                <label for="password">Password*</label>
                                <span class="text-danger" *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required"> Password is required
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="isUser == 'user' || isUser == 'store-manager'" class="website-menu-form">
                        <div class="form mt-0">
                            <h5>Select store to give access</h5>
                            <ng-select [items]="stores_list" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" groupBy="selectedAllGroup" bindLabel="company_name" [closeOnSelect]="false" [multiple]="true" bindValue="uid" formControlName="store" (change)="select_branch($event)" placeholder="Select Store" [(ngModel)]="selectedStore">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [ngModel] ="item$.selected"/> Select All
                                  </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <mat-checkbox style="pointer-events: none" color="primary" id="item-{{index}}" [checked]="item$.selected">
                                        {{item.company_name}}
                                    </mat-checkbox>
                                </ng-template>
                            </ng-select>
                            <span class="text-danger" *ngIf="(registerFormControl.store.touched || submitted) && registerFormControl.store.errors?.required">Please select atleast one Store
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-store-button">
                <button type="submit" class="btn" *ngIf="!loading">Add</button>
                <button class="sign-in-btn btn-light " style="background:#71B01D;border:none;padding:0;text-align:center;width: 36px;height: 36px;border-radius: 32px;" *ngIf="loading"><img src="./assets/imgs/loading.gif" style="height: 22px;"></button>
            </div>
        </div>
    </form>
</section>
<div class="side-menu-branding" style="padding-left: 15px;margin-bottom: 0;height:64px;">
<!--   <mat-icon class="brand-logo" svgIcon="angular-logo"></mat-icon>
 -->    
<div>
 <img src="./assets/imgs/DIGITAL-MENUS-PRO-admin.png" class="brand-logo" style="height: 40px;">
</div>
</div> 
<ul class="side-menu-navigation nav flex-column" *ngIf="!ghost_mode && (user.role =='admin' || user.role =='admin-manager' || user.role =='user' || user.role =='store-manager')" style="padding-top:0;">
  <li class="menu-item expandable-item nav-item" [routerLink]="['/admin/dashboard']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff !important;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/dashboard' || currenturl == '/admin/dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-dashboard.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Admin Dashboard</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/dashboard' &&  currenturl != '/admin/dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-dashboard.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Admin Dashboard</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>

  <li class="menu-item expandable-item nav-item" [routerLink]="['/admin/stores']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff !important;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/admin/stores'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-so.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Stores</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/admin/stores'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-so.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Stores</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>

    <li class="menu-item expandable-item nav-item" *ngIf="user.role =='admin' || user.role =='admin-manager'" [routerLink]="['/admin/all-items']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff !important;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/admin/all-items'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-saved-white.svg" style="margin-top: 2px;margin-left: 7px;margin-right: 12px;">
			<span class="menu-item-title">All Products</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/admin/all-items'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-saved-black.svg" style="margin-top: 2px;margin-left: 7px;margin-right: 12px;">
			<span class="menu-item-title">All Products</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>

  <li class="menu-item expandable-item nav-item" *ngIf="user.role =='admin' || user.role =='admin-manager'" [routerLink]="['/admin/all-recipes']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff !important;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/admin/all-recipes'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-saved-white.svg" style="margin-top: 2px;margin-left: 7px;margin-right: 12px;">
			<span class="menu-item-title">All Recipes</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/admin/all-recipes'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-saved-black.svg" style="margin-top: 2px;margin-left: 7px;margin-right: 12px;">
			<span class="menu-item-title">All Recipes</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>

  <li class="menu-item expandable-item nav-item" *ngIf="user.role =='admin'" [routerLink]="['/admin/users-permission']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff !important;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl == '/admin/users-permission'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-staff.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">User Permissions</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl != '/admin/users-permission'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-staff.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">User Permissions</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>

  <li class="menu-item expandable-item nav-item" *ngIf="user.role =='admin' || user.role =='admin-manager' " [routerLink]="['/admin/branch-group']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff !important;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl == '/admin/branch-group'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-incident.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Store Groups</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl != '/admin/branch-group'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-incident.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Store Groups</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>

</ul>

<!-- <ul class="side-menu-navigation nav flex-column" *ngIf="user.role =='admin'" style="padding-top: 0">
  <li class="menu-item expandable-item nav-item" [routerLink]="['/consultant/dashboard']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<mat-icon class="menu-item-icon" svgIcon="">dashboard</mat-icon>
			<span class="menu-item-title">Admin Dashboard</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: #76B100!important;height: 42px;width:100%;" *ngIf="currenturl !='dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<mat-icon class="menu-item-icon" svgIcon="">dashboard</mat-icon>
			<span class="menu-item-title">Admin Dashboard</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>
 <li class="menu-item expandable-item nav-item" [routerLink]="['/consultant/dashboard']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/page2'">
		<div class="item-header-content">
		  <mat-panel-title>
			<mat-icon class="menu-item-icon" svgIcon="">dashboard</mat-icon>
			<span class="menu-item-title">Page2</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: #76B100!important;height: 42px;width:100%;" *ngIf="currenturl !='/manager/dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<mat-icon class="menu-item-icon" svgIcon="">dashboard</mat-icon>
			<span class="menu-item-title">Page2</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>
  
  <li class="menu-item expandable-item nav-item">
	<mat-expansion-panel [expanded]="val" style="padding-inline-end:0;">
	  <mat-expansion-panel-header class="menu-link">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="../../../assets/imgs/icons/settings.svg" class="menu-item-icon" style="width: 20px;height: 20px;">
			<span class="menu-item-title" style="margin-left:8px;">CMS Pages</span>
		  </mat-panel-title>
		</div>  
	  </mat-expansion-panel-header>
	  <ul class="menu-sub-navigation nav flex-column">
		 
	  

		 <li class="menu-item nav-item" style="background: #a020f0;color:#fff;border-radius: 5px;" *ngIf="currenturl =='/faq'">
		  <a class="menu-link nav-link" [routerLink] ="['/faq']">
			<i class="menu-item-icon"></i>
			<span class="menu-item-title">FAQ </span>
		  </a>
		</li>
		<li class="menu-item nav-item" *ngIf="!currenturl.includes('/faq')">
		  <a class="menu-link nav-link" [routerLink] ="['/faq']">
			<i class="menu-item-icon"></i>
			<span class="menu-item-title">FAQ</span>
		  </a>
		</li>
		 <li class="menu-item nav-item" style="background: #a020f0;color:#fff;border-radius: 5px;" *ngIf="currenturl =='/terms'">
		  <a class="menu-link nav-link" [routerLink] ="['/terms']">
			<i class="menu-item-icon"></i>
			<span class="menu-item-title">Terms & Conditions</span>
		  </a>
		</li>
		<li class="menu-item nav-item" *ngIf="!currenturl.includes('/terms')">
		  <a class="menu-link nav-link" [routerLink] ="['/terms']">
			<i class="menu-item-icon"></i>
			<span class="menu-item-title">Terms & Conditions</span>
		  </a>
		</li>
		 <li class="menu-item nav-item" style="background: #a020f0;color:#fff;border-radius: 5px;" *ngIf="currenturl =='/privacy'">
		  <a class="menu-link nav-link" [routerLink] ="['/privacy']">
			<i class="menu-item-icon"></i>
			<span class="menu-item-title">Privacy Policy</span>
		  </a>
		</li>
		<li class="menu-item nav-item" *ngIf="!currenturl.includes('/privacy')">
		  <a class="menu-link nav-link" [routerLink] ="['/privacy']">
			<i class="menu-item-icon"></i>
			<span class="menu-item-title">Privacy Policy</span>
		  </a>
		</li>
	  
	  </ul>
	</mat-expansion-panel>
  </li>
</ul> --> 

<ul class="side-menu-navigation nav flex-column" *ngIf="user.role =='manager'" style="padding-top:0;">
  <li class="menu-item expandable-item nav-item" [routerLink]="['/manager/dashboard']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/manager/dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-dashboard.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Dashboard</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/manager/dashboard'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-dashboard.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Dashboard</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>
   <li class="menu-item expandable-item nav-item" [routerLink]="['/manager/staff']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/manager/staff'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-staff.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Staff</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/manager/staff'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-staff.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Staff</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>
  <li class="menu-item expandable-item nav-item" [routerLink]="['/manager/logs']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/manager/logs'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-log.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Logs</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/manager/logs'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-log.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Logs</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>
  <li class="menu-item expandable-item nav-item" [routerLink]="['/manager/incidents']">
	<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
	  <mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/manager/incidents'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-white-incident.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Incident Reports</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	  <mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/manager/incidents'">
		<div class="item-header-content">
		  <mat-panel-title>
			<img src="assets/imgs/icon-menu-black-incident.png" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Incident Reports</span>
		  </mat-panel-title>
		</div>
	  </mat-expansion-panel-header>
	</mat-expansion-panel>
  </li>
</ul>
<!-- STORE NAVIGATION STARTS HERE --> 
	<ul class="side-menu-navigation nav flex-column" *ngIf="ghost_mode && (user.role =='store' || user.role == 'group' || user.role == 'user' || user.role == 'store-manager' || user.role == 'admin' || user.role == 'admin-manager')" style="padding-top:0;">
	 	<li class="menu-item expandable-item nav-item" [routerLink]="['/store/dashboard']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/dashboard'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-dashboard-white.svg" style="margin-top:0px;margin-right: 6px;">
			<span class="menu-item-title">Dashboard</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/dashboard'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-dashboard-black.svg" style="margin-top:0px;margin-right: 6px;">
			<span class="menu-item-title">Dashboard</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/store/digital-circular']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/digital-circular' || currenturl =='/store/digital-circular/upcoming-layout' || currenturl =='/store/digital-circular/no-ad-layout'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-circular-white.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Digital Circular</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/digital-circular' && currenturl !='/store/digital-circular/upcoming-layout' && currenturl !='/store/digital-circular/no-ad-layout' ">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-circular-black.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Digital Circular</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>


		<li class="menu-item expandable-item nav-item" [routerLink]="['/store/all-items']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/saved-items' || currenturl == '/store/all-items'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-saved-white.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">All Products</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/all-items' && currenturl !='/store/saved-items'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-saved-black.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">All Products</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/store/coupons']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/coupons'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-coupon-white.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Coupons</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/coupons'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-coupon-black.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Coupons</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/store/recipes']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/recipes'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-recipe-white.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Recipes</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/recipes'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-recipe-black.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Recipes</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/store/store-info']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/store-info'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-storeinfo-white.svg" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Store Information</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/store-info'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-storeinfo-black.svg" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Store Information</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>
		<li class="menu-item expandable-item nav-item" [routerLink]="['/store/broadcast']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/store/broadcast'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/icon-menu-broadcast-white.png" style="margin-top:-6px;margin-right: 6px;width:28px;height:28px;">
			<span class="menu-item-title">Broadcast</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/store/broadcast'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/icon-menu-broadcast-black.png" style="margin-top:-6px;margin-right: 6px;width:28px;height:28px;">
			<span class="menu-item-title">Broadcast</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>
	</ul>
<!-- 
GROUP STARTS HERE -->
	<ul class="side-menu-navigation nav flex-column" *ngIf="user.role =='groupx'" style="padding-top:0;">
			
		<li class="menu-item expandable-item nav-item" [routerLink]="['/group/dashboard']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/group/dashboard'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-dashboard-white.svg" style="margin-top:0px;margin-right: 6px;">
			<span class="menu-item-title">Dashboard</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/group/dashboard'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-dashboard-black.svg" style="margin-top:0px;margin-right: 6px;">
			<span class="menu-item-title">Dashboard</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/group/digital-circular']">
		<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
		<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/group/digital-circular'">
		<div class="item-header-content">
		<mat-panel-title>
		<img src="assets/imgs/menu/icon-menu-circular-white.svg" style="margin-top:1px;margin-right: 6px;">
		<span class="menu-item-title">Digital Circular</span>
		</mat-panel-title>
		</div>
		</mat-expansion-panel-header>
		<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/group/digital-circular'">
		<div class="item-header-content">
		<mat-panel-title>
		<img src="assets/imgs/menu/icon-menu-circular-black.svg" style="margin-top:1px;margin-right: 6px;">
		<span class="menu-item-title">Digital Circular</span>
		</mat-panel-title>
		</div>
		</mat-expansion-panel-header>
		</mat-expansion-panel>
		</li>


		<li class="menu-item expandable-item nav-item" [routerLink]="['/group/saved-items']">
		<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
		<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/group/saved-items'">
		<div class="item-header-content">
		<mat-panel-title>
		<img src="assets/imgs/menu/icon-menu-saved-white.svg" style="margin-top:1px;margin-right: 6px;">
		<span class="menu-item-title">Saved Products</span>
		</mat-panel-title>
		</div>
		</mat-expansion-panel-header>
		<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/group/saved-items'">
		<div class="item-header-content">
		<mat-panel-title>
		<img src="assets/imgs/menu/icon-menu-saved-black.svg" style="margin-top:1px;margin-right: 6px;">
		<span class="menu-item-title">Saved Products</span>
		</mat-panel-title>
		</div>
		</mat-expansion-panel-header>
		</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/group/coupons']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/group/coupons'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-coupon-white.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Coupons</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/group/coupons'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-coupon-black.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Coupons</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/group/recipes']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/group/recipes'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-recipe-white.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Recipes</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/group/recipes'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-recipe-black.svg" style="margin-top:1px;margin-right: 6px;">
			<span class="menu-item-title">Recipes</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>

		<li class="menu-item expandable-item nav-item" [routerLink]="['/group/store-info']">
			<mat-expansion-panel [expanded]="false" hideToggle style="background-color:#fff!important;">
			<mat-expansion-panel-header class="menu-link" style="background-color:#fff!important;border-radius: 5px;background:#76B100!important;color:#fff;margin-left: 1rem;padding: 1rem;    height: 42px;width:100%;" *ngIf="currenturl =='/group/store-info'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-storeinfo-white.svg" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Store Information</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			<mat-expansion-panel-header class="menu-link" style="background: transparent !important;color:#000;height: 42px;width:100%;" *ngIf="currenturl !='/group/store-info'">
			<div class="item-header-content">
			<mat-panel-title>
			<img src="assets/imgs/menu/icon-menu-storeinfo-black.svg" style="margin-top:-6px;margin-right: 6px;">
			<span class="menu-item-title">Store Information</span>
			</mat-panel-title>
			</div>
			</mat-expansion-panel-header>
			</mat-expansion-panel>
		</li>
</ul>
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/shared/services/auth.service';
import { ComponentsService } from '../../components.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';


@Component({
  selector: 'app-users-permission',
  templateUrl: './users-permission.component.html',
  styleUrls: ['./users-permission.component.scss']
})
export class UsersPermissionComponent implements OnInit {
  modalRef:NgbModalRef;
  storeaddForm: FormGroup;
  filter_store_form: FormGroup;
  group_add_form:FormGroup;
  filteruserform: FormGroup;

  completedMarketingTasks: number;
  completedSalesTasks: number;
  completedCustomerSupportTasks: number;
  isBrowser: boolean;
  tasks: any;
  users:any;
  currentTab:any;
    
  datasource = [];
  currentrole:any = 'all';
  groupaddlist:any;
  users_list:any;

  warehouseaddlist:any;
  leadsTableDataSource: any;
  statuslist:any= [{name: "Client"},{name:"Trial"}];
  tableColumns  :  string[] = ['id', 'Name', 'Email', 'Role', 'Store', 'actions'];
  closeResult: string;
  title = 'appBootstrap';

  @ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
  @ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
  @ViewChild('groupModal') groupModal;
  @ViewChild('warehouseModal') warehouseModal;
  @ViewChild('mymodal') mymodal;
  @ViewChild('closebtn', {static: true}) closebtn:ElementRef;
  @ViewChild('storeupdateModal') storeupdateModal;

  constructor( @Inject(PLATFORM_ID) private platformId: object,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private _snackBar: MatSnackBar) { 
		this.filter_store_form = new FormGroup({
			warehouse_id: new FormControl(0),
			store_id: new FormControl(),
    	});
	}

  ngOnInit() {
    this.afs.collection('/users',ref=>ref.where('role','in', ['user', 'admin', 'admin-manager','store-manager']).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
      this.users_list = data.map(e => {
        return{
          uid: e.payload.doc.id,
          displayName: e.payload.doc.data()['displayName'],
		  role: e.payload.doc.data()['role'],
		  email : e.payload.doc.data()['email'],
		  store : e.payload.doc.data()['store']?.map((x)=> {return x.company_name})
        };
      });
      this.users_list = new MatTableDataSource(this.users_list);
      this.users_list.sort = this.leadsSort;
      this.users_list.paginator = this.leadsPaginator;
    });
  }


  filter_store(value){
  	if(value.store_id == null && value.warehouse_id == null){
  		this._snackBar.open("Error: No parameters to filter", '', {
	      duration: 3000,
	    });
	    return false;
  	}
  	if(value.warehouse_id == 0 && value.store_id != "" && value.store_id != null){
  		this.users_list.filter = value.store_id.trim().toLocaleLowerCase();
  	} 
  	else if(value.warehouse_id != "" && value.store_id != "" && value.warehouse_id != null && value.store_id != null ){
  		this.afs.collection('/users',ref=>ref.where('role','==','user').where('role','==','store-manager').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.users_list = data.map(e => {
				return{
				uid: e.payload.doc.id,
				company_name: e.payload.doc.data()['company_name'],
				email: e.payload.doc.data()['email'],
				group_id: e.payload.doc.data()['group_id'],
				group_name: e.payload.doc.data()['group_name'],
				phone: e.payload.doc.data()['phone'],
				store_id: e.payload.doc.data()['store_id'],
				store_owner_name: e.payload.doc.data()['store_owner_name'],
				warehouse_id: e.payload.doc.data()['warehouse_id'],
				warehouse_name: e.payload.doc.data()['warehouse_name'],
				created_at:e.payload.doc.data()['created_at'],
				can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				status:e.payload.doc.data()['status'],
				user_id:e.payload.doc.data()['user_id'],
				branch_count: e.payload.doc.data()['branch_count']
				};
			});
			this.users_list = new MatTableDataSource(this.users_list);
			this.users_list.sort = this.leadsSort;
			this.users_list.paginator = this.leadsPaginator;

			this.users_list.filter = value.store_id.trim().toLocaleLowerCase();
		});
  	}
  	else if(value.warehouse_id != 0 && value.warehouse_id != null){
  		this.afs.collection('/users',ref=>ref.where('role','==','user').where('role','==','store-manager').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.users_list = data.map(e => {
			return{
				uid: e.payload.doc.id,
				company_name: e.payload.doc.data()['company_name'],
				email: e.payload.doc.data()['email'],
				group_id: e.payload.doc.data()['group_id'],
				group_name: e.payload.doc.data()['group_name'],
				phone: e.payload.doc.data()['phone'],
				store_id: e.payload.doc.data()['store_id'],
				store_owner_name: e.payload.doc.data()['store_owner_name'],
				warehouse_id: e.payload.doc.data()['warehouse_id'],
				warehouse_name: e.payload.doc.data()['warehouse_name'],
				created_at:e.payload.doc.data()['created_at'],
				can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				status:e.payload.doc.data()['status'],
				user_id:e.payload.doc.data()['user_id'],
				branch_count: e.payload.doc.data()['branch_count']
			};
		});	
			this.users_list = new MatTableDataSource(this.users_list);
			this.users_list.sort = this.leadsSort;
			this.users_list.paginator = this.leadsPaginator;
		});

  	} else{
  		this.ngOnInit();
  	}
  	this._snackBar.open("Filter applied successfully", '', {
	      duration: 1000,
	    });
  }

	ForgotPassword(passwordResetEmail) {
		return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
		// window.alert('Password reset email sent, check your inbox.');
		}).catch((error) => {
		//window.alert(error)
		})
	}

  deleteUser(id) {
	const deleteUser = firebase.functions().httpsCallable("deleteAuthUser");
		deleteUser({id}) // not deleteUser(uid)
		.then((result) => {
  	})
	let aa = this.afs.doc('/users/'+id).delete().then(docRef => {
		this._snackBar.open('User deleted successfully', '', {
			duration: 1000,
		  });
	});
  }

  addNewUser(){
  	this.router.navigate(['/admin/add-new-user']);
  }

  	edit_store(uid){
  		this.router.navigate(['/admin/user-edit/'+ uid]);
  	}

}

import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {finalize} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-store-info',
	templateUrl: './store-info.component.html',
	styleUrls: ['./store-info.component.scss']
})
export class StoreInfoComponent implements OnInit {


store_data:any;
user:any;
current_store_uid:any;
get_current_store_id:any;
bannerImageSrc: any;


store_form: FormGroup;
ref:any;
task:any;
imagesrc:any='';
imagesrc_icon:any ='';
imageurl:any='';
c=true
resizingFactor = 0.8;
quality = 1;
compressedImageBase64:any
showCompressBlock:boolean
live_url:any
@ViewChild('canvas', { static: true }) canvasRef: ElementRef<HTMLCanvasElement>;

ghost_mode:boolean = false;
	compressedImageBase64_icon: any;
	banner_image: any ='';
	compressedImageBase64_banner: any;

	constructor( @Inject(PLATFORM_ID) 
	private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,
	public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private afStorage : AngularFireStorage,
	private modalService: NgbModal,
	private storage: AngularFireStorage,
	private _snackBar: MatSnackBar,
	private elementRef: ElementRef,
	private http:HttpClient
	) {
	 }

	ngOnInit(): void {


		this.store_form = this.formBuilder.group({
			company_name 			: new FormControl(""),
			company_name_slug 		: new FormControl(""),
			address	 				: new FormControl(""), 
			phone	 				: new FormControl(""),	
			social_facebook_url 	: new FormControl(""),
			displayRecipePage       : new FormControl(""),
			displayCouponPage       : new FormControl(""),
			displayCircularPage     : new FormControl(""),
			displayMenuPage         : new FormControl(""),
			displayBundlePage       : new FormControl(""),
			social_instagram_url 	: new FormControl(""),
			social_youtube_url 		: new FormControl(""),
			social_twitter_url 		: new FormControl(""),
			theme_base_color 		: new FormControl(""),
			branch_name_color 		: new FormControl(""),
			theme_text_color 		: new FormControl(""),
		});

		let chkghost = localStorage.getItem('ghost_mode');
  		if(chkghost)
  		{
  			this.ghost_mode = true;
  		} else {
  			this.ghost_mode = false;
  		}
  		this.get_current_store_id = localStorage.getItem('current_store_id');
  		if(this.get_current_store_id)
  		{
  			this.user = JSON.parse(this.get_current_store_id);
  		} else{
  			this.user = JSON.parse(localStorage.getItem('userrole'));
  		}
  		this.current_store_uid = this.user.store_uid;

		//this.user = JSON.parse(localStorage.getItem('userrole'));
		//this.current_store_uid = this.user.store_uid;


		this.afs.collection('/stores',ref=>ref.where('uid','==', this.current_store_uid)).snapshotChanges().subscribe(data => {
			this.store_data = data.map(e => {
				return{
					company_name      	: e.payload.doc.data()['company_name'],
					company_name_slug	: e.payload.doc.data()['company_name_slug'],
					address 			: e.payload.doc.data()['address']?e.payload.doc.data()['address']:"",
					phone  				: e.payload.doc.data()['phone']?e.payload.doc.data()['phone']:"",
					social_facebook_url : e.payload.doc.data()['social_facebook_url']?e.payload.doc.data()['social_facebook_url']:"",
					displayRecipePage   : e.payload.doc.data()['displayRecipePage']?e.payload.doc.data()['displayRecipePage']:false,
					displayCouponPage   : e.payload.doc.data()['displayCouponPage']?e.payload.doc.data()['displayCouponPage']:false,
					displayCircularPage : e.payload.doc.data()['displayCircularPage']?e.payload.doc.data()['displayCircularPage']:false,
					displayMenuPage     : e.payload.doc.data()['displayMenuPage    ']?e.payload.doc.data()['displayMenuPage    ']:false,
					displayBundlePage   : e.payload.doc.data()['displayBundlePage']?e.payload.doc.data()['displayBundlePage']:false,
					social_instagram_url: e.payload.doc.data()['social_instagram_url']?e.payload.doc.data()['social_instagram_url']:"",
					social_youtube_url  : e.payload.doc.data()['social_youtube_url']?e.payload.doc.data()['social_youtube_url']:"",
					social_twitter_url 	: e.payload.doc.data()['social_twitter_url']?e.payload.doc.data()['social_twitter_url']:"",
					theme_base_color 	: e.payload.doc.data()['theme_base_color']?e.payload.doc.data()['theme_base_color']:"",
					branch_name_color 	: e.payload.doc.data()['branch_name_color']?e.payload.doc.data()['branch_name_color']:"",
					theme_text_color 	: e.payload.doc.data()['theme_text_color']?e.payload.doc.data()['theme_text_color']:"",
					store_logo 			: e.payload.doc.data()['store_logo']?e.payload.doc.data()['store_logo']:"",
					icons	 			: e.payload.doc.data()['icons']?e.payload.doc.data()['icons']:"",
					banner_image	 			: e.payload.doc.data()['banner_image']?e.payload.doc.data()['banner_image']:"",
					//created_at        : e.payload.doc.data()['created_at'],
				};
			}); 
			
			if(window.location.origin == 'https://admin-digitalcircular.web.app'){
				this.live_url = `https://store-digitalcircular.web.app/${this.store_data[0]?.company_name_slug}`
			}else if(window.location.origin == 'https://admin.digitalmenuspro.com'){
				this.live_url = `https://store.digitalmenuspro.com/${this.store_data[0]?.company_name_slug}`
			}else{
				this.live_url = `https://digital-menus-pro.web.app/${this.store_data[0]?.company_name_slug}`
			}

			this.imagesrc = this.store_data[0]?.store_logo;
			this.imagesrc_icon = this.store_data[0]?.icons;
			this.banner_image = this.store_data[0]?.banner_image;
			if(Array.isArray(this.imagesrc_icon)){
				this.imagesrc_icon = this.imagesrc_icon[0];
			}
			this.store_form.patchValue({  
				company_name 		: this.store_data[0]?.company_name,
				company_name_slug 	: this.store_data[0]?.company_name_slug,
		 		address 			: this.store_data[0]?.address,
		 		phone 				: this.store_data[0]?.phone,
		 		social_facebook_url	: this.store_data[0]?.social_facebook_url,
				displayRecipePage	: this.store_data[0]?.displayRecipePage,
				displayCouponPage	: this.store_data[0]?.displayCouponPage,
				displayCircularPage	: this.store_data[0]?.displayCircularPage,
				displayMenuPage	    : this.store_data[0]?.displayMenuPage,
				displayBundlePage	: this.store_data[0]?.displayBundlePage,
		 		social_instagram_url: this.store_data[0]?.social_instagram_url,
		 		social_youtube_url	: this.store_data[0]?.social_youtube_url,
		 		social_twitter_url  : this.store_data[0]?.social_twitter_url,
		 		theme_base_color 	: this.store_data[0]?.theme_base_color,
		 		branch_name_color  	: this.store_data[0]?.branch_name_color || "#666666",
		 		theme_text_color  	: this.store_data[0]?.theme_text_color || "#ffffff",
		 	});
		});

	}

	getImage(){
		return document.getElementById('logotest')
	}
	getCompressedImage(){
		return document.getElementById('compressedImage')
	}

	async onFileInput(e){
		const file = e.target.files[0];
		if(file){
			this.showCompressBlock = true;
			this.imagesrc = await this.fileToDataUri(file);
			(<HTMLImageElement>this.getCompressedImage()).src = this.imagesrc
			this.compressedImageBase64 = this.imagesrc
		}
	}

	async onFileInput_icon(e) {
		const file = e.target.files[0];
		if(file){
			this.imagesrc_icon = await this.fileToDataUri(file);
			this.compressedImageBase64_icon = this.imagesrc_icon;
		}
	}
	async onFileInput_banner_image(e) {
		const file = e.target.files[0];
		if(file){
			this.banner_image = await this.fileToDataUri(file);
			this.compressedImageBase64_banner = this.banner_image;
		}
	}
	getImageDimensions() {
		const img: HTMLImageElement = this.elementRef.nativeElement.querySelector('#logotest_icon');
		let img_dimensions = [];
		img_dimensions['width'] = img.naturalWidth;
		img_dimensions['height'] = img.naturalHeight;
		return img_dimensions;
	}
	onResizing(e){
		const compImg = this.getCompressedImage();
		(<HTMLImageElement>compImg).style.width = 'auto'
		this.resizingFactor = parseInt(e.target.value) / 100;
  		const base64Data =  this.compressImage(this.getImage(), this.resizingFactor, this.quality);
		  (<HTMLImageElement>compImg).src = base64Data
		  this.compressedImageBase64 = base64Data
		this.setImageSize(base64Data)  
	}

	setImageSize(base64String){
		var stringLength = base64String.split(',')[1].length;
		var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
		document.getElementById("size").innerHTML = this.formatBytes(sizeInBytes)
	}

	fileToDataUri(field) {
		return new Promise((resolve) => {
		  const reader = new FileReader();
		  reader.addEventListener("load", () => {
			resolve(reader.result);
		  });
		  reader.readAsDataURL(field);
		});
	  }

	formatBytes(bytes, decimals = 2) {
		if (!+bytes) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}

	compressImage(imgToCompress, resizingFactor, quality) {
		// showing the compressed image
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		const originalWidth = imgToCompress.width;
		const originalHeight = imgToCompress.height;

		const canvasWidth = originalWidth * resizingFactor;
		const canvasHeight = originalHeight * resizingFactor;
	  
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
	  
		context.drawImage(
		  imgToCompress,
		  0,
		  0,
		  originalWidth * resizingFactor,
		  originalHeight * resizingFactor
		);
			
		return canvas.toDataURL();
	  }

	  async generateIcons(storeName,iconUrl:any): Promise<any[]> {
		return new Promise(resolve => {
		const iconSizes = [192, 512]; // icon sizes to generate
		// const iconSizes = [48, 72, 96, 144, 192, 256, 384, 512]; // icon sizes to generate
		const img = new Image();
		img.src = iconUrl;
		img.crossOrigin = "anonymous"
		img.onload = async () => {
		const urls = await Promise.all(
			iconSizes.map(async (size) => {
			  const canvas = this.canvasRef.nativeElement;
			  canvas.width = size;
			  canvas.height = size;
			  const ctx = canvas.getContext('2d');
			  ctx?.drawImage(img, 0, 0, size, size);
			  const dataUrl = canvas.toDataURL('image/png');
			  const fileName = `${storeName}-icon-${size}x${size}.png`;
			  const file = this.dataURLtoFile(dataUrl, fileName);
			  const ref = this.storage.ref(`uploads/${fileName}`);
			  await ref.put(file);
			  const downloadURL = await ref.getDownloadURL().toPromise()
			  return downloadURL
			})
		  )
			resolve(urls)	
		};
		})

	  }
	  dataURLtoFile(dataUrl: string, fileName: string): File {
		const arr = dataUrl.split(',');
		// const mime = arr[0].match(/:(.*?);/);
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
		  u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], fileName, { type: 'image/png' });
	  }	  

	  handleUpload(){
		const randomId = "digital-menus-"+ new Date().getTime() +  Math.random().toString(36).substring(2);
		this.ref = this.afStorage.ref('uploads/'+randomId);
		this.task =  this.ref.putString(this.compressedImageBase64.split(',')[1], 'base64', {contentType:'image/jpg'});
			this.task.snapshotChanges().pipe(
				finalize(() => {
					this.ref.getDownloadURL().subscribe(async url => {
						this.showCompressBlock = false;
						(<HTMLInputElement>document.getElementById('file-input')).value = '';
						this.imagesrc = url;
						this.store_form.patchValue({
							store_logo 	:  url,
						});
						let store_data = {
							store_logo 	: url,
						};
						let update_to_db = this.afs.doc('/stores/'+this.current_store_uid).set(store_data,{merge:true}).then(docRef => {
						});
					});
				})
			).subscribe(); 
			this._snackBar.open("Logo uploaded successfully",'',{
				duration: 1500,
			});
	  }
	  handleUploadBannerImage(){
		const randomId = "digital-menus-"+ new Date().getTime() +  Math.random().toString(36).substring(2);
		this.ref = this.afStorage.ref('uploads/'+randomId);
		this.task =  this.ref.putString(this.compressedImageBase64_banner.split(',')[1], 'base64', {contentType:'image/jpg'});
			this.task.snapshotChanges().pipe(
				finalize(() => {
					this.ref.getDownloadURL().subscribe(async url => {
						this.showCompressBlock = false;
						(<HTMLInputElement>document.getElementById('bannerFileInput')).value = '';
						this.bannerImageSrc = url;
						this.store_form.patchValue({
							banner_image 	:  url,
						});
						let store_data = {
							banner_image 	: url,
						};
						let update_to_db = this.afs.doc('/stores/'+this.current_store_uid).set(store_data,{merge:true}).then(docRef => {
							this._snackBar.open("Banner Inage uploaded successfully",'',{
								duration: 1500,
							});
						});
					});
				})
			).subscribe(); 
	  }

	  async handleUpload_icon(){
			let img_height_width = this.getImageDimensions();
			if(img_height_width['width'] < 512 || img_height_width['height'] < 512){
				this._snackBar.open("Image size must be atleast 512.",'',{
					duration: 1500,
				});
				return;
			}
			const iconURLs = await this.generateIcons(this.store_form.get("company_name_slug").value ,this.compressedImageBase64_icon);
			
			this.showCompressBlock = false;
			let store_data = {
				icons:iconURLs,
				manifestURL:""
			};

			// const obj = {
			// 	"fileName": this.store_data[0]?.company_name_slug + ".json",
			// 	"data": {
			// 		"name": this.store_data[0]?.company_name,
			// 		"short_name": "DD",
			// 		"theme_color": "#026e00",
			// 		"background_color": "#333",
			// 		"display": "standalone",
			// 		"scope": this.live_url,
			// 		"start_url": this.live_url,
			// 		"icons": [
								
			// 			{
			// 				"src": iconURLs[0],
			// 				"sizes": "192x192",
			// 				"type": "image/png",
			// 				"purpose": "maskable any"
			// 			},
			// 			{
			// 				"src": iconURLs[1],
			// 				"sizes": "512x512",
			// 				"type": "image/png",
			// 				"purpose": "maskable any"
			// 			}
			// 		]
			// 	}
			// }

			// this.http.post<{message:string, url:string, fileName:string}>(environment.ServerURL + `/manifest/update`, obj).subscribe(res=>{
			// 	store_data.manifestURL = environment.ServerURL + res.url
			// },err=>{
			// })
		
			let update_to_db = this.afs.doc('/stores/'+this.current_store_uid).set(store_data,{merge:true}).then(docRef => {
				this._snackBar.open("Icon uploaded successfully",'',{
					duration: 1500,
				});
			});
	  }
	color_change(event,from_group)
	{
		if(from_group === 'theme_base_color'){
			this.store_form.patchValue({  
				theme_base_color: event.target.value,
			});
		}
		if(from_group === 'branch_name_color'){
			this.store_form.patchValue({  
				branch_name_color: event.target.value,
			});
		}
	}

	color_input_change(event,from_group)
	{

		if(from_group === 'theme_base_color'){
			if(event.target.value.length == 7){
				this.store_form.patchValue({  
					theme_base_color 	: event.target.value,
				});
			}
		}
		if(from_group === 'branch_name_color'){
			if(event.target.value.length == 7){
				this.store_form.patchValue({  
					branch_name_color 	: event.target.value,
				});
			}
		}
		
	}

	update_store_info(data)
	{
		let store_data = {
			address 			: data.address,
			phone 				: data.phone,
			theme_base_color 	: data.theme_base_color,
			branch_name_color 	: data.branch_name_color,
			theme_text_color 	: data.theme_text_color,
			social_facebook_url : data.social_facebook_url ? /^(https?:\/\/)/.test(data.social_facebook_url) ? data.social_facebook_url : 'https://'+data.social_facebook_url : '',
			displayRecipePage : data.displayRecipePage ?  data.displayRecipePage : false,
			displayCouponPage : data.displayCouponPage ? data.displayCouponPage  : false,
			displayCircularPage : data.displayCircularPage ? data.displayCircularPage  : false,
			displayMenuPage : data.displayMenuPage ? data.displayMenuPage  : false,
			displayBundlePage : data.displayBundlePage ? data.displayBundlePage  : false,
			social_twitter_url 	: data.social_twitter_url ? /^(https?:\/\/)/.test(data.social_twitter_url) ? data.social_twitter_url : 'https://'+data.social_twitter_url : '',
			social_youtube_url 	: data.social_youtube_url ? /^(https?:\/\/)/.test(data.social_youtube_url) ? data.social_youtube_url : 'https://'+data.social_youtube_url : '',
			social_instagram_url 	: data.social_instagram_url ? /^(https?:\/\/)/.test(data.social_instagram_url) ? data.social_instagram_url : 'https://'+data.social_instagram_url : '',
		};
		let update_to_db = this.afs.doc('/stores/'+this.current_store_uid).set(store_data,{merge:true}).then(docRef => {
				});

		this._snackBar.open("Information updated successfully!",'',{
			duration: 1500,
		});
	}
	deleteImage(field: string) {
		let updateData = {};
		updateData[field] = '';
		this.afs.doc('/stores/' + this.current_store_uid).update(updateData).then(() => {
		  this._snackBar.open("Image deleted successfully", '', {
			duration: 1500,
		  });
		  if (field === 'store_logo') {
			this.imagesrc = '';
		  } else if (field === 'icons') {
			this.imagesrc_icon = '';
		  } else if (field === 'banner_image') {
			this.banner_image = '';
		  }
		}).catch(error => {
		  this._snackBar.open("Failed to delete image", '', {
			duration: 1500,
		  });
		  console.error("Error deleting image: ", error);
		});
	}
}
